export const SET_CAR_TYPE_DETAILS = 'SET_CAR_TYPE_DETAILS';
export const setCarTypeDetails = (carType) => ({
    type: SET_CAR_TYPE_DETAILS,
    payload: carType,
});

export const SET_SHOULD_FETCH_CAR_TYPES = 'SET_SHOULD_FETCH_CAR_TYPES';
export const setShouldFetchCarTypes = (shouldFetchCarTypes) => ({
    type: SET_SHOULD_FETCH_CAR_TYPES,
    payload: shouldFetchCarTypes,
});

export const SET_CAR_TYPE_TOTAL_ITEMS = 'SET_CAR_TYPE_TOTAL_ITEMS';
export const setCarTypeTotalItems = (totalItems) => ({
    type: SET_CAR_TYPE_TOTAL_ITEMS,
    payload: totalItems,
});