import {
    SET_DEALERSHIP_GROUP_DATA,
    SET_DEALERSHIP_GROUP_DETAILS,
    SET_DEALERSHIP_GROUP_DEALERSHIPS,
    SET_DEALERSHIP_GROUP_OWNERS,
    SET_DEALERSHIP_GROUP_COUNTRIES,
    SET_DEALERSHIP_GROUP_USERS,
    SET_DEALERSHIP_GROUP_TOTAL_ITEMS,
} from '../actions/dealershipGroupActions.js';

const initialState = {
    data: null,
    details: null,
    dealerships: [],
    owners: {},
    countries: {},
    users: {},
    totalItems:0,
};

const dealershipGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEALERSHIP_GROUP_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case SET_DEALERSHIP_GROUP_DETAILS:
            return {
                ...state,
                details: action.payload,
            };
        case SET_DEALERSHIP_GROUP_DEALERSHIPS:
            return {
                ...state,
                dealerships: action.payload,
            };
        case SET_DEALERSHIP_GROUP_OWNERS:
            return {
                ...state,
                owners: action.payload,
            };
        case SET_DEALERSHIP_GROUP_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
            };
        case SET_DEALERSHIP_GROUP_USERS:
            return {
                ...state,
                users: action.payload,
            };
        case SET_DEALERSHIP_GROUP_TOTAL_ITEMS:
            return {
                ...state,
                totalItems: action.payload,
            };
        default:
            return state;
    }
};

export default dealershipGroupReducer;
