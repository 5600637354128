export default class CountryDataTransformer {
    supports(resource) {
        return resource === 'countries' ;
    }
    transformCollection(data) {
        return data['hydra:member'].map(resource => ({ ...resource, id: resource['iso3'] }));
    }

    transformSingle(data) {
        return { ...data, id: data['iso3'] };
    }

    // transformCollectionBeforeUpdate(data) {
    //     return data['hydra:member'].map(resource => ({ ...resource, id: resource['iso3'] }));
    // }

    // transformSingleBeforeUpdate(data) {
    //     return { ...data, id: data['iso3'] };
    // }
}
