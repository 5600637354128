import queryString from 'query-string';
// import {store} from '../../store';

class AssetOverridesEndpointResolver {
    supports(resource) {
        return resource === 'assetOverrides';
    }
    getOneUri(_resource, params) {
        return `/asset-overrides/${params.id}`
    }

    getUpdateUri(_resource, params){
        const id = params.id.split('/').pop();
        return `/asset-overrides/${id}`
    }

    getListUri(_resource, params){
        const queryParams = {
            ...params.filter,
            page:params.pagination.page,
            perPage:params.pagination.perPage,
        }
        return `/asset-overrides?${queryString.stringify(queryParams)}`
    }

    getCreateUri(){
        return '/asset-overrides';
    }

}

export default AssetOverridesEndpointResolver;