import React from 'react';

const ErrorIcon = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            caution-large
        </title>
        <path d="M2.5,42.5 L23.5,4.5 L44.5,42.5 L2.5,42.5 Z M23.5,33 L23.5,37 M23.5,18 L23.5,29" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default ErrorIcon;
