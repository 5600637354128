import './MyLoginPage.css';

const MyLoginPage = () => {
    const handleSubmit = () => window.location.href = '/connect';
    return (
        <div className='login_div'>
            <div className='login_page_title'>Admin Retail Content Manager<span className='login_page_version'> {process.env.REACT_APP_VERSION || 'x.x.x'} - Internal System</span></div>
            <button
                className='login_button'
                onClick={()=>handleSubmit()}
            >
                Login
            </button>
        </div>
    );
};
export default MyLoginPage;