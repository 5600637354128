import React from 'react';
const X = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            cancel-small
        </title>
        <path d="M5.9,17.1 L17.1,5.9 M5.9,5.9 L17.1,17.1" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
)
export default X;
