import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {combineReducers} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import carModelReducer from '../reducers/carModelReducer';
import carTypeReducer from '../reducers/carTypeReducer';
import moduleTypeReducer from '../reducers/moduleTypeReducer';
import userReducer from '../reducers/userReducer';
import channelReducer from '../reducers/channelReducer';
import dealershipReducer from '../reducers/dealershipReducer';
import dealershipGroupReducer from '../reducers/dealershipGroupReducer';
import deviceReducer from '../reducers/deviceReducer';
import faqReducer from '../reducers/faqReducer';
import brandSwitcherReducer from '../reducers/brandSwitcherReducer';
import pageReducer from '../reducers/pageReducer';
import assetOverridesReducer from '../reducers/assetOverridesReducer';
import adminReducer from '../reducers/adminReducer';

const rootReducer = combineReducers({
    carModel: carModelReducer,
    carType: carTypeReducer,
    moduleType: moduleTypeReducer,
    user: userReducer,
    channel: channelReducer,
    dealership: dealershipReducer,
    dealershipGroup: dealershipGroupReducer,
    device: deviceReducer,
    faq: faqReducer,
    brand: brandSwitcherReducer,
    page: pageReducer,
    assetOverrides: assetOverridesReducer,
    admin:adminReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['brand', 'carModel', 'carType', 'page', 'moduleType', 'user', 'channel', 'dealership', 'dealershipGroup', 'device', 'faq', 'dealership' ] //the resources that will be persisted
  whitelist:['brand']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true
});

const persistor = persistStore(store);

export {store, persistor};