import React, {useEffect, useState} from 'react';
import './styles/Custom.css';
import {
    Edit,
    SimpleForm,
    TextInput,
    Create,
    NumberInput,
    useDataProvider,
     ShowButton,
    SimpleShowLayout,
    Show,
    TextField,
    NumberField,
    ArrayField,
    CreateButton,
    TopToolbar,
    SaveButton,
    Toolbar,
    useNotify,
    useRedirect,
    useRecordContext,
    Title,
    EditButton,
    Button,
    required,
    Confirm,
} from 'react-admin';
import Loader from '../components/Loader';
import {useParams} from 'react-router-dom';
import { BRAND_DETAILS } from './Brands';
import {useDispatch, useSelector} from 'react-redux';
import {CustomImageField} from '../fields/CustomImageField';
import { setCarModelDetails, setCarModelTotalItems } from '../actions/carModelActions.js';
import {setShouldFetchCarTypes} from '../actions/carTypeActions.js';
import ErrorComponent from '../components/ErrorComponent';
import NotFound from '../components/Profile/NotFound/NotFound';
import Pagination from '../components/Pagination';
import { setPage } from '../actions/pageActions';
import {useError} from '../contexts/ErrorContext';
import DeleteIcon from '../assets/icons/DeleteIcon';

export const useFetchCarModels = (pageToSet) => {
    const page = pageToSet || useSelector(state => state.page.page || 1);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const carModels = useSelector((state) => state.carModel.details || []);
    const shouldFetchCarTypes = useSelector((state) =>state.carType.shouldFetchCarTypes)
    const [loading, setLoading] = useState(carModels.length === 0);

    const fetchCarModels = async () => {
        setLoading(true);
        try {
            const carModelParams = {
                pagination: { page: page},
            };
            const { data, total } = await dataProvider.getList('carModels', carModelParams);
            // const updatedModels = await changeCarTypesUrlsIntoReadableNames(data);
            const updatedModels = data;

            dispatch(setCarModelTotalItems(total));
            dispatch(setCarModelDetails(updatedModels));
            // setLoading(false);
        } catch (error) {
            console.error('Error fetching car models:', error);
            setLoading(false);
        }
        finally{
            setLoading(false);
        }
    };

    // const changeCarTypesUrlsIntoReadableNames = async (models) => {
    //     const updatedModels = await Promise.all(
    //         models.map(async (model) => {
    //             if (model.carTypes.length > 0) {
    //                 try {
    //                     const uuid = model.id.split('/').pop();
    //                     const carTypesData = await dataProvider.getList('carTypes', {
    //                         pagination: { page: 1, perPage: 1000 },
    //                         sort: { field: 'id', order: 'ASC' },
    //                         filter: {brand: selectedBrand, carModelUuid:uuid},
    //                     });

    //                     const carTypesDetails = carTypesData.data.map((carTypeData) => ({
    //                         id: carTypeData.id,
    //                         type: carTypeData.type,
    //                     }));
    //                     return { ...model, carTypesDetails };
    //                 } catch (error) {
    //                     console.error('Error fetching car types:', error);
    //                     return { ...model, carTypesDetails: [] };
    //                 }
    //             } else {
    //                 return { ...model, carTypesDetails: [] };
    //             }
    //         })
    //     );
    //     return updatedModels;
    // };

    useEffect(() => {
        if (!carModels || carModels.length===0){
            fetchCarModels();
        }
    }, []);

    useEffect(()=>{
        fetchCarModels();
    },[page, selectedBrand])

    useEffect(() => {
        if (shouldFetchCarTypes) {
          fetchCarModels();
          dispatch(setShouldFetchCarTypes(false));
        }
    }, [page, shouldFetchCarTypes]);


    return loading ;
};

const CarModelListActions = () => (
    <TopToolbar>
        <CreateButton label='Create' />
    </TopToolbar>
);

export const CarModelList = () => {
    const dispatch = useDispatch();
    const {error} = useError();
    const loading = useFetchCarModels();
    const carModels = useSelector(state=>state.carModel.details || []);
    const page = useSelector(state => state.page.page);
    const totalItems = useSelector(state=>state.carModel.totalItems || 0);
    const selectedBrand = useSelector(state => state.brand.brand);

    const handlePageChange = (newPage) => {
        console.log('newPage=====',newPage);
        dispatch(setPage(newPage))
    };

    useEffect(() => {
        if(selectedBrand){
            dispatch(setPage(1));
        }
    }, [selectedBrand, dispatch]);

    const content = error ? (
        <ErrorComponent error={error} />
        ) : (
        <div className='customDataGrid_carModels'>
            <CarModelListActions />
            <Title title='Car Models'/>
            <div className='create_button_carModel_div'></div>
            <table className='carModels_table'>
                <thead>
                    <tr className='carModelHead'>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Image</th>
                        <th>Position</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {carModels.map(carModel => {
                    const brandCode = carModel.brand.split('/').pop();
                    const brand = BRAND_DETAILS.find(b => b.code === brandCode);
                    const brandName = brand ? brand.name : carModel.brand;

                    return(
                        <tr key={carModel.id} className='carModelRow'>
                            <td>{brandName}</td>
                            <td>{carModel.model}</td>
                            <td>
                                <img className='carModelImage' src={carModel.imageUrl} alt={carModel.model} />
                            </td>
                            <td>{carModel.position}</td>
                            <td className='crud_buttons'>
                                <EditButton className='edit-button' basepath='/carModels' record={carModel} />
{/*
                            <td>
                                <Link to={`/carModels/${carModel.id}?page=${page}`}>
                                    Edit
                                </Link>
                            </td> */}
                                <ShowButton className='show-button' basepath='/carModels' record={carModel} />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <Pagination total={totalItems} current={page} onChange={handlePageChange} />
        </div>
        );

    if (loading) return <Loader/>
    if (!error && (!carModels || carModels.length === 0) ) { 
        return (<>  
        <CarModelListActions />
        <NotFound resource='car models'/>
    </>)}


    return (
        <>
            {content}
        </>
    );
};

const CustomDeleteButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleDelete = async () => {
        setOpen(false);
        try {
            await dataProvider.delete('carModels', { id: record.id });
            notify('Model deleted successfully', 'info');
            redirect('/carModels');
        } catch (error) {
            notify(`Error deleting Model: ${error.message}`, 'warning');
        }
    };
    return (
        <>
            <Button label="Delete" onClick={() => setOpen(true)} style = {{marginLeft:'10px',color:'red'}}>
                <DeleteIcon />
            </Button>

            <Confirm
                isOpen={open}
                loading={false}
                title="Are you sure you want to delete this model?"
                content="This action is irreversible."
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
            />
        </>
    );
};


const CustomToolbar = props => {
    const isAdmin = localStorage.getItem('isAdmin');
    return (
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'space-between'}}>
            <SaveButton />
            {isAdmin === 'true' && <CustomDeleteButton />}
        </Toolbar>
)};

export const CarModelEdit = (props) => {
    const dispatch = useDispatch();
    const loading = useFetchCarModels();
    const { id } = useParams();
    const carModels = useSelector(state => state.carModel.details);
    const carModel = carModels ? carModels.find(model => model.uuid === id) : null;

    const dataProvider=useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const [error, setError] = useState(null);

    if (loading){
        return <Loader/>
    }
    if (!carModel) {
        return <NotFound resource='car model'/>;
    }

    const onSuccess = async (data) => {
        try {
            let dataToSet = {};
            dataToSet.model = data.model;
            dataToSet.imageUrl = data.imageUrl;
            dataToSet.position = data.position;

            await dataProvider.update('carModels', { id: data['@id'], data: dataToSet });
            const updatedCarModels = carModels.map((model) => {
                if (model.id === data.id) {
                  return { ...model, ...data };
                }
                return model;
            });

            dispatch(setCarModelDetails(updatedCarModels));
            notify('Car model updated successfully', 'info');
            redirect(`/carModels/${data.id}/show`);
        } catch (error) {
            console.error('Error updating car model:', error);
            notify(`Error updating car model: ${error.message}`, 'warning');
            setError(error);
        }
    };
    const onError= (error) => {
        console.error('Error updating car model:', error);
        notify(`Error updating ${error.message}`,'warning')
        setError(error);
    }

    if (error) {
        return <ErrorComponent error = {error}/>
    }

    return(
        <Edit {...props} mutationOptions = {{onSuccess, onError}} title='Car Model Edit'>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput source='model' label='Model' fullWidth validate={[required()]}/>
                <TextInput source='imageUrl' label='Image URL' fullWidth multiline validate={[required()]}/>
                <NumberInput source='position' label='Position' fullWidth validate={[required()]}/>
            </SimpleForm>
        </Edit>
    )
};

const extractCarModelData = (data) => {
    return {
        '@id':data['@id'],
        '@type':data['@type'],
        brand: data.brand,
        carTypes: data.carTypes || [],
        carTypesDetails: data.carTypesDetails || [],
        id: data.uuid,
        imageUrl: data.imageUrl,
        model: data.model,
        position: data.position,
        uuid:data.uuid,
    };
};

export const CarModelCreate = (props) => {
    useFetchCarModels();
    const dispatch = useDispatch();
    const notify = useNotify();
    const redirect= useRedirect();
    const carModels = useSelector((state) => state.carModel.details || []);
    const [error, setError] = useState(null);
    const selectedBrand = useSelector(state => state.brand.brand);

    const onSuccess = async (data) => {
        try {
            const extractedData = extractCarModelData(data);
            notify('Car model created successfully', 'info');
            redirect(`/carModels/${data.id}/show`);

            const updatedCarModels = [extractedData, ...carModels]
            dispatch(setCarModelDetails(updatedCarModels));
        } catch (error) {
            console.error('Error creating car model:', error);
            notify(`Error creating car model: ${error.message}`, 'warning');
            setError(error);
        }
    };

    const onError= (error) => {
        console.error('Error creating car model:', error);
        notify(`Error creating ${error.message}`,'warning')
        setError(error);
    }

    if (error) {
        return <ErrorComponent error = {error} />
    }
    return (
        <Create {...props} mutationOptions={{onSuccess, onError}}>
            <SimpleForm defaultValues={()=>({brand: '/brands/'+selectedBrand })}>
                <TextInput source='model' label='Model (example: A4)' fullWidth validate={[required()]} />
                <TextInput source='imageUrl' label='ImageUrl' fullWidth multiline validate={[required()]} />
                <NumberInput source='position' label='Position' fullWidth validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};

const ShowListOfCarTypes = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [carTypes, setCarTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCarTypes = async () => {
            if (record && record.carTypes) {
                try{
                    const fetchedCarTypes = await Promise.all(
                        record.carTypes.map(async url => {
                            const carTypeId = url.split('/').pop();
                            const { data } = await dataProvider.getOne('carTypes', { meta: { id: url}});
                            return { id: carTypeId, type: data.type };
                        })
                    );
                    setCarTypes(fetchedCarTypes);
                }
                catch (error) {
                    setError(error);
                }
                finally{
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchCarTypes();
    }, [record, dataProvider]);

    if (loading) {
        return <Loader/>;
    }
    if (error) {
        return <div>Error loading car types: {error.message}</div>;
    }
    return carTypes.map(carType => <p key={carType.id}>{carType.type}</p>);
};

const BrandNameField = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    const brandCode = record[source].split('/').pop();
    const brand = BRAND_DETAILS.find(b => b.code === brandCode);
    return <span>{brand ? brand.name : 'Unknown'}</span>;
};

// const CarModelShowActions = () => (
//     <TopToolbar>
//         <EditButton label='Edit' />
//     </TopToolbar>
// );

const CustomShowActions = () => {
    const record = useRecordContext();
    const redirect = useRedirect();

    const handleEdit = () => {
        if (record && record.id) {
            redirect(`/carModels/${record.id}/edit`);
        }
    };

    return (
        <TopToolbar>
            {/* Add any other buttons you might need here */}
            <Button onClick={handleEdit} color="primary" >
                Edit
            </Button>
        </TopToolbar>
    );
};


export const CarModelShow = ({props}) => {
    const { id } = useParams();
    const loading = useFetchCarModels();
    const carModels = useSelector(state => state.carModel.details);
    const carModel = carModels ? carModels.find(model => model.uuid === id) : null;

    if (loading){
        return <Loader/>
    }
    if (!carModel) {
        return <NotFound resource='car models'/>
    }

    return (
        // <Show {...props} title='Car Model Show' actions = {CarModelShowActions}  >

        <Show {...props} title='Car Model Show' actions = {<CustomShowActions/>}>
            <SimpleShowLayout>
                <BrandNameField source='brand' label='Brand' />
                <TextField source='model' label='Model' />
                <CustomImageField source='imageUrl' label='Image' title='model' style={{ height: '270px', width: 'auto', border: '1px solid red' }} />
                <NumberField source='position' label='Position' />
                <ArrayField source='carTypes' label='Car Types'>
                    <ShowListOfCarTypes />
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    )
};