import {store} from '../../store';

class ModuleTypeEndpointResolver {
    supports(resource) {
        return resource === 'moduleTypes';
    }

    getOneUri(_resource, params) {
        const state = store.getState();
        const brand = state.brand.brand === 'N' ? 'V' : state.brand.brand;
        return `/brands/${brand}/module-types/${params.id}`;
    }

    getUpdateUri(_resource, params) {
        const state = store.getState();
        const brand = state.brand.brand === 'N' ? 'V' : state.brand.brand;
        return `/brands/${brand}/module-types/${params.id}`;
    }

    getListUri = () => {
        const state = store.getState();
        const brand = state.brand.brand === 'N' ? 'V' : state.brand.brand;
        return `/brands/${brand}/module-types`;
    };

    getCreateUri = () => {
        const state = store.getState();
        const brand = state.brand.brand === 'N' ? 'V' : state.brand.brand;
        return `/brands/${brand}/module-types`;
    };
}

export default ModuleTypeEndpointResolver;