export default class FaqDataTransformer {
    supports(resource) {
        return resource === 'faqs' ;
    }
    transformCollection(data) {
        return data['hydra:member'].map(resource => ({ ...resource, id: resource['uuid'] }));
    }

    transformSingle(data) {
        return { ...data, id: data['uuid'] };
    }
}
