const DevicesIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            display-small
        </title>
        <path d="M2.5,2.5 L23.5,2.5 L23.5,2.5 L23.5,15.5 C23.5,16.6045695 22.6045695,17.5 21.5,17.5 L0.5,17.5 L0.5,17.5 L0.5,4.5 C0.5,3.3954305 1.3954305,2.5 2.5,2.5 Z M9.5,17.5 L9.5,21.5 M14.5,17.5 L14.5,21.5 M5.5,21.5 L18.5,21.5 M0.5,14.5 L23.5,14.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default DevicesIcon;
