import React from 'react';

const RolesIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            system-settings-small
        </title>
        <path d="M12.7,6.7 C13.6,6.9 14.4,7.4 15,8 L16.9,7 L18.2,9.1 L16.3,10.3 C16.5,11.1 16.5,12 16.3,12.8 L18.3,14 L17,16.1 L15,14.9 C14.4,15.5 13.6,16 12.7,16.2 L12.7,18.5 L10.2,18.5 L10.2,16.2 C9.4,16 8.6,15.6 8,14.9 L6.1,16 L4.8,14 L6.8,12.8 C6.6,11.9 6.6,11 6.8,10.2 L4.8,9.1 L6.2,7 L8,8 C8.6,7.4 9.4,7 10.2,6.8 L10.2,4.5 L12.7,4.5 L12.7,6.7 Z M11.5,13.6 C12.659798,13.6 13.6,12.659798 13.6,11.5 C13.6,10.340202 12.659798,9.4 11.5,9.4 C10.340202,9.4 9.4,10.340202 9.4,11.5 C9.4,12.659798 10.340202,13.6 11.5,13.6 Z M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);
export default RolesIcon;