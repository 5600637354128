import React from 'react';

const BackIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            back-small
        </title>
        <path d="M14 16.9L8.5 11.5 14 6.1" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default BackIcon;
