import React from 'react';

const ChannelsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            climate-small
        </title>
        <path d="M9,10.8 L9.1,10 C9.2,-0.1 5.8,1.6 4.5,2.9 C3,4.4 1.7,7.4 3.8,9.5 C5.2,10.7 7,11.2 8.8,11 L9,10.8 Z M10.6,14.9 C0.5,14.8 1.5,18.8 3,20.3 C4.5,21.8 7.3,23.2 9.4,21 C10.7,19.5 11.4,17.5 11.1,15.6 L10.9,14.9 C10.9,14.9 10.6,14.9 10.6,14.9 Z M14.7,13.6 C14.9,13.5 15.4,13.3 15.4,13.3 C15.4,13.3 18.6,12.6 20.8,14.7 C23,16.8 21.4,19.5 19.9,21 C18.4,22.5 14.4,24 14.5,13.9 C14.5,13.9 14.5,13.8 14.7,13.6 Z M13.4,9 C13.2,8.1 13,4.8 14.7,3.1 C16.9,1 19.9,2.3 21.4,3.8 C23.2,5.6 25.2,9 15,9 L13.4,9 L13.4,9 Z M12,9 C10.3,9 9,10.3 9,12 C9,13.7 10.3,15 12,15 C13.7,15 15,13.7 15,12 C15,10.3 13.7,9 12,9 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default ChannelsIcon;
