import {
    TextInput,
    EditButton,
    useDataProvider,
    Edit,
    Create,
    CreateButton,
    SimpleForm,
    ArrayInput,
    SimpleFormIterator,
    SimpleShowLayout,
    TextField,
    Show,
    ShowButton,
    useRecordContext,
    TopToolbar,
    Toolbar,
    useNotify,
    useRedirect,
    Title,
} from 'react-admin';
import {useParams} from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import {setModuleTypeDetails, setModuleTypeCountryRestrictions } from '../actions/moduleTypeActions.js';
import {useDispatch, useSelector} from 'react-redux';
import {BRAND_DETAILS} from './Brands';
import './styles/Custom.css';
import Loader from '../components/Loader.js';
import NotFound from '../components/Profile/NotFound/NotFound.js';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';

export const useFetchModuleTypes = () =>{
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const moduleTypes = useSelector(state => state.moduleType.details || []);
  const [loading, setLoading] = useState(!moduleTypes || moduleTypes.length === 0);
  const selectedBrand = useSelector(state => state.brand.brand);

  const fetchModuleTypes = async () => {
      try {
        setLoading(true);

          const moduleTypeParams = {
              pagination: { page: 1 }
          };

          const { data } = await dataProvider.getList('moduleTypes', moduleTypeParams);

          dispatch(setModuleTypeDetails(data));
          setLoading(false);
      } catch (error) {
          console.error('Error fetching module types:', error);
          setLoading(false);
      }
  }

  useEffect(() => {
        fetchModuleTypes();
  }, [selectedBrand]);

  return loading;
}

const useFetchResources = () => {
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const moduleTypes = useSelector(state => state.moduleType.details || []);
  const [loadingResources, setLoadingResources] = useState(true);

  const fetchAllCountryRestrictions = async () => {
    if (moduleTypes.length===0){
      setLoadingResources(false);
      return;
    }

    const countryCodes = new Set(moduleTypes.flatMap(mt => mt.countryRestrictions));
    const promises = Array.from(countryCodes).map(code =>
      dataProvider.getOne('countries', { id: code }).catch(() => ({ data: { id: code, name: code }}))
    );

    try {
      const results = await Promise.all(promises);
      const countriesMap = results.reduce((acc, { data }) => {
        if (data && data.id) {
          acc[data.id] = data.name || data.id;
        }
        return acc;
      }, {});
      dispatch(setModuleTypeCountryRestrictions(countriesMap));
    } catch (error) {
      console.error('Error fetching country restrictions:', error);
    } finally{
      setLoadingResources(false);
    }
  };

  useEffect(()=>{
    fetchAllCountryRestrictions();
  },[]);
  return {loadingResources};
}

const ModuleTypeListActions = () => (
  <TopToolbar>
      <CreateButton label='Create' />
  </TopToolbar>
);

const CustomToolbar = props => (
  <Toolbar {...props}>
      <button className='save_button'>
          <SaveIcon fontSize='small'/>
          <div className='save_text'>SAVE</div>
      </button>
  </Toolbar>
);

export const ModuleTypeList = () => {
    const loading = useFetchModuleTypes();
    const moduleTypes = useSelector(state => state.moduleType.details || []);
    const {loadingResources} = useFetchResources();
    const countryNamesMapping = useSelector(state => state.moduleType.countryRestrictions || {});

    if (loading || loadingResources) return <Loader/>;
    if(!moduleTypes || moduleTypes.length===0) return <NotFound resource = 'module types' />

    return (
      <div className='customDataGrid_moduleTypes'>
          <Title title='Module Types' />
          <ModuleTypeListActions/>
          <table className='moduleTypes_table'>
              <thead>
                  <tr className='carModelHead'>
                      <th>Code</th>
                      <th>Brand</th>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Country Restrictions</th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
              {moduleTypes.map(moduleType => {
                const brandCode = moduleType.brand.split('/').pop();
                const brand = BRAND_DETAILS.find(b => b.code === brandCode);
                const brandName = brand ? brand.name : moduleType.brand;

                const countryNames = moduleType.countryRestrictions.map(code => countryNamesMapping[code] || code).join(', ');

                return(
                  <tr key={moduleType.id} className='moduleTypeRow'>
                      <td>{moduleType.code}</td>
                      <td>{brandName}</td>
                      <td>{moduleType.title}</td>
                      <td>{moduleType.category}</td>
                      <td>
                          {countryNames}
                      </td>
                      <td className='crud_buttons'>
                          <EditButton className='edit-button' basepath='/moduleTypes' record={moduleType} />
                          <ShowButton className='show-button' basepath='/moduleTypes' record={moduleType} />
                      </td>
                  </tr>
                )
              })}
              </tbody>
          </table>
      </div>
    );
};

export const ModuleTypeEdit = props => {
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const loading = useFetchModuleTypes();
  const { id } = useParams();
  const moduleTypes = useSelector(state => state.moduleType.details);
  const moduleType = moduleTypes ? moduleTypes.find(module => module.code === id) : null;

  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryRef = useRef(searchQuery);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [loadingCountries, setLoadingCountries ] = useState();

  const fetchCountries = async () => {
      if (searchQuery.length>2) {
          try {
              const response = await dataProvider.getList('countries', {
                  filter: { name: searchQuery },
                  pagination: { page: 1, perPage: 30 },
                  sort: { field: 'iso3', order: 'ASC' }
              });

              if(searchQueryRef.current === searchQuery){
                  setSearchResults(response.data)
              }
          } catch (error) {
              console.error('Error fetching countries:', error);
          }
      } else {
          setSearchResults([]);
      }
  };

  const handleSearchChange = (event) => {
      const query = event.target.value
      setSearchQuery(query);
      if(query.length <=2 ){
          setSearchResults([]);
      }
  };

  const renderCountries = () => {
    return (
        <div>
            {selectedCountries.map((country, index) => (
                <div key={index} className='country'>
                    <span className='searched_name_or_title'>
                      {country.name}
                    </span>
                    <button className='remove_button' type='button'
                        onClick={() => handleCountryRemove(country)}>
                        <CloseIcon  />
                    </button>
                </div>
            ))}
        </div>
    );
  };

  const fetchCountriesDetails = async (countryRestrictions) => {
    try {
      const countryDetails = await Promise.all(countryRestrictions.map(iso3 =>
          dataProvider.getList('countries', {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'id', order: 'ASC' },
            filter: { iso3: iso3 }
          })
      ));
      const countries = countryDetails.flatMap(response => response.data);
      setSelectedCountries(countries);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const fetchData = async () => {
    if (moduleType) {
        const fetchPromises = [];

        //get initial country Restrictions
        if (moduleType.countryRestrictions) {
          fetchPromises.push(fetchCountriesDetails(moduleType.countryRestrictions));
        }

        try {
          await Promise.all(fetchPromises);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingCountries(false);
        }
    }
  };

  const handleCountrySelect = (country) => {
    setSelectedCountries(prev => {
      const isDuplicate = prev.some(existingCountry => existingCountry.id === country.id);
      return isDuplicate ? prev : [...prev, country];
    });
    setSearchResults([]);
  };

  const handleCountryRemove = (countryToRemove) => {
    setSelectedCountries(prevCountries =>
      prevCountries.filter(country => country.id !== countryToRemove.id)
    );
  };

  useEffect(() => {
    fetchCountries();
  }, [searchQuery, dataProvider]);

  useEffect(()=>{
    searchQueryRef.current = searchQuery;
  },[searchQuery])

  useEffect(() => {
    fetchData();
  }, [moduleType, dataProvider]);


  if (loading || loadingCountries) return <Loader/>
  if (!moduleType) return <NotFound resource='module types' />

  const onSuccess = async (data) => {
    try {
      const restrictedCountriesISO3 = selectedCountries.map(country => country.iso3);
      const updatedModuleTypeData = {
        title: data.title,
        category: data.category,
        countryRestrictions: restrictedCountriesISO3,
      };

      await dataProvider.update('moduleTypes', {
        id: id,
        data: updatedModuleTypeData,
        previousData: moduleType
      });

      const updatedModuleTypes = moduleTypes.map((module) => {
        if (module.id === data.id) {
          return { ...module, ...updatedModuleTypeData };
        }
        return module;
      });
      dispatch(setModuleTypeDetails(updatedModuleTypes));

      notify('Module type updated successfully', 'info');
      redirect(`/moduleTypes/${data.id}/show`);

      // Additional logic to update local state if necessary...
    } catch (error) {
      console.error('Error updating module type:', error);
      notify(`Error updating module type: ${error.message}`, 'warning');
    }
  };

  const onError= (error) => {
      console.error('Error updating module type1:', error);
      notify(`Error updating ${error.message}`,'warning')
  }

  return (
    <Edit {...props} mutationOptions = {{onSuccess, onError}} title='Module Type Edit' >
      <SimpleForm toolbar={<CustomToolbar/>}>
        <TextInput fullWidth source='title' />
        <TextInput fullWidth source='category'/>




            <h3 className='countries_title'>Country Restrictions:</h3>
              <div className='countries'>
                {renderCountries()}

                <div className='search_container'>
                    <input
                        className='countries_searchbar'
                        type='text'
                        placeholder='Search for country'
                        value = {searchQuery}
                        onChange={handleSearchChange}
                    />
                    <SearchIcon className='search_icon'/>
                </div>

                {searchResults.map(country => (
                    <div className = 'search-result-options' key={country.id} onClick={() => handleCountrySelect(country)}>
                        {country.name}
                    </div>
                ))}
            </div>
      </SimpleForm>
    </Edit>
  );
};

const extractModuleTypeData = (data) => {
  return {
      '@id':data['@id'],
      '@type':data['@type'],
      brand: data.brand,
      category:data.category,
      code:data.code,
      countryRestrictions:data.countryRestrictions,
      id:data.id,
      slug:data.slug,
      title:data.title,
  };
};

export const ModuleTypeCreate = props => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const notify = useNotify();
  const moduleTypes = useSelector((state)=> state.moduleType.details || []);
  const selectedBrand = useSelector(state => state.brand.brand);

  const onSuccess = async (data) => {
    try {
        const extractedData = extractModuleTypeData(data);
        notify('Module type created successfully', 'info');
        redirect('/moduleTypes');
        const updatedModuleTypes = [extractedData, ...moduleTypes]
        dispatch(setModuleTypeDetails(updatedModuleTypes));
    } catch (error) {
        console.error('Error creating module type:', error);
        notify(`Error creating module type: ${error.message}`, 'warning');
    }
};

const onError= (error) => {
    console.error('Error creating module type:', error);
    notify(`Error creating ${error.message}`,'warning')
}

  return (
    <Create {...props} mutationOptions={{onSuccess, onError}} title='Create Module Type'>
      <SimpleForm defaultValues={()=>({brand: '/brands/'+selectedBrand })}>
        <TextInput fullWidth source='code' />
        <TextInput fullWidth source='title' />
        <TextInput fullWidth source='category'/>
        <ArrayInput fullWidth source='countryRestrictions' label='List of Country Restrictions (Add iso3 code, for example: DEU)'>
          <SimpleFormIterator>
              <TextInput fullWidth label='Must not be empty'/>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const BrandNameField = ({ source }) => {
  const record = useRecordContext();
  if (!record) return null;
  const brandCode = record[source].split('/').pop();
  const brand = BRAND_DETAILS.find(b => b.code === brandCode);
  return <span> {brand ? brand.name : 'Unknown'}</span>;
};

const CountryNamesField = ({ record = {}, source }) => {
  const countryRestrictions = record[source];
  if (!countryRestrictions) {
    return <div>No country restrictions</div>;
  }
  const countryNamesMapping = useSelector(state => state.moduleType.countryRestrictions || {});

  const restrictions = Array.isArray(countryRestrictions) ?
                       countryRestrictions :
                       Object.values(countryRestrictions);
  const countryNamesDisplay = restrictions
    .map(code => countryNamesMapping[code] || code)
    .join(', ');

  return (
    <div>
      {countryNamesDisplay || ''}
    </div>
  );
};

export const ModuleTypeShow = props => {
  const loading = useFetchModuleTypes();
  const {loadingResources} = useFetchResources();
  const { id } = useParams();
  const moduleTypes = useSelector(state => state.moduleType.details);
  const moduleType = moduleTypes ? moduleTypes.find(module => module.code === id) : null;

  if (loading || loadingResources){
      return <Loader/>
  }
  if (!moduleType) {
      return <NotFound resource='module types' />
  }

  return (
    <Show {...props} title='Module Type Show'>
      <SimpleShowLayout>
        <TextField source='code' />
        <BrandNameField source='brand' label = 'Brand' />
        <TextField source='title' />
        <TextField source='category'/>
         <CountryNamesField source="countryRestrictions" record={moduleType} label="Country Restrictions" />
      </SimpleShowLayout>
    </Show>
  );
};
