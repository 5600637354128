import React from 'react';

const UsersIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            UsersIcon
        </title>
        <path d="M15,18.5 L22.5,18.5 L22.5,12.5 C22.5,11.9 22.1,11.5 21.5,11.5 L12,11.5 M15.5,2.5 L17.5,2.5 C18.6,2.5 19.5,3.4 19.5,4.5 L19.5,6.5 C19.5,7.6 18.6,8.5 17.5,8.5 L15.5,8.5 C14.4,8.5 13.5,7.6 13.5,6.5 L13.5,4.5 C13.5,3.4 14.4,2.5 15.5,2.5 Z M1.5,20.5 L1.5,14.5 C1.5,13.9 1.9,13.5 2.5,13.5 L12.5,13.5 C13.1,13.5 13.5,13.9 13.5,14.5 L13.5,20.5 L1.5,20.5 Z M6.5,4.5 L8.5,4.5 C9.6,4.5 10.5,5.4 10.5,6.5 L10.5,8.5 C10.5,9.6 9.6,10.5 8.5,10.5 L6.5,10.5 C5.4,10.5 4.5,9.6 4.5,8.5 L4.5,6.5 C4.5,5.4 5.4,4.5 6.5,4.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default UsersIcon;