export default class DeviceDataTransformer {
    supports(resource) {
        return resource === 'devices' ;
    }
    transformCollection(data) {
        return data['hydra:member'].map(resource => ({ ...resource, id: resource['deviceNumber'] }));
    }

    transformSingle(data) {
        return { ...data, id: data['deviceNumber'] };
    }
}
