export const SET_MODULE_TYPE_DATA='SET_MODULE_TYPE_DATA';
export const setModuleTypeData = (data) =>({
    type:SET_MODULE_TYPE_DATA,
    payload:data
})

export const SET_MODULE_TYPE_DETAILS = 'SET_MODULE_TYPE_DETAILS';
export const setModuleTypeDetails = (moduleType) => ({
    type: SET_MODULE_TYPE_DETAILS,
    payload: moduleType,
});

export const SET_MODULE_TYPE_COUNTRY_RESTRICTIONS = 'SET_MODULE_TYPE_COUNTRY_RESTRICTIONS';
export const setModuleTypeCountryRestrictions = (countryRestrictions) => ({
    type: SET_MODULE_TYPE_COUNTRY_RESTRICTIONS,
    payload: countryRestrictions,
});