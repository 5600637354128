export default class ChannelDataTransformer {
    supports(resource) {
        return resource === 'channels' ;
    }
    transformCollection(data) {
        return data['hydra:member'].map(resource => ({ ...resource, id: resource['slug'] }));
    }

    transformSingle(data) {
        return { ...data, id: data['slug'] };
    }
}