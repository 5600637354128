import queryString from 'query-string';
import {store} from '../../store';

class DealershipEndpointResolver {
    supports(resource) {
        return resource === 'dealerships';
    }

    getOneUri(_resource, params) {
        const state = store.getState();
        return `/brands/${state.brand.brand}/dealerships/${params.id}`;
    }

    getListUri = (_resource, params) => {
        const state = store.getState();

        const {filter, pagination} = params;
        const queryParams = {
            ...filter,
            page: pagination.page,
        }
        return `/brands/${state.brand.brand}/dealerships?${queryString.stringify(queryParams)}`;
    }

    getUpdateUri = (_resource, params) => {
        return params.id;
    }

    getCreateUri = () => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/dealerships`;
    }

    getDeleteUri = (_resource, params) => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/dealerships/${params.id}`;
    }
}

export default DealershipEndpointResolver;