import React from 'react';

const Pagination = ({ total, current, onChange }) => {
    const numPages = Math.ceil(total / 30);
    const pageNumbers = [];
    const radius = 2; // Number of pages shown around the current page

    for (let i = Math.max(1, current - radius); i <= Math.min(numPages, current + radius); i++) {
        pageNumbers.push(
            <button
                key={i}
                onClick={() => onChange(i)}
                disabled={current === i}
                className={`paginationButton ${current === i ? 'active' : ''}`}
            >
                {i}
            </button>
        );
    }

    return (
        <div className="paginationContainer">
            {current > 1 && (
                <>
                    <button onClick={() => onChange(1)} className="paginationButton">First</button>
                    <button onClick={() => onChange(current - 1)} className="paginationButton">Prev</button>
                </>
            )}
            {pageNumbers}
            {current < numPages && (
                <>
                    <button onClick={() => onChange(current + 1)} className="paginationButton">Next</button>
                    <button onClick={() => onChange(numPages)} className="paginationButton">Last</button>
                </>
            )}
        </div>
    );
};

export default Pagination;