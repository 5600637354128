import { SET_USER_DATA, SET_USER_DETAILS, SET_SHOULD_FETCH_USER_RESOURCES, SET_USER_ROLES, SET_USER_COUNTRIES,SET_USER_CHANNELS, SET_USER_DEALERSHIPS, SET_USER_DEALERSHIP_GROUPS, SET_USER_TOTAL_ITEMS } from '../actions/userActions.js';

const initialState = {
    data:null,
    details:null,
    shouldFetchUserResources:true,
    roles: null,
    countries: null,
    channels: null,
    dealerships:null,
    dealershipGroups:null,
    totalItems:0,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case SET_USER_DETAILS:
            return {
                ...state,
                details: action.payload,
            };
        case SET_SHOULD_FETCH_USER_RESOURCES:
            return {
                ...state,
                shouldFetchUserResources: action.payload,
            };
        case SET_USER_ROLES:
            return {
                ...state,
                roles: action.payload,
            };
        case SET_USER_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
            };
        case SET_USER_CHANNELS:
            return {
                ...state,
                channels: action.payload,
            };
        case SET_USER_DEALERSHIPS:
            return {
                ...state,
                dealerships: action.payload,
            };
        case SET_USER_DEALERSHIP_GROUPS:
            return {
                ...state,
                dealershipGroups: action.payload,
            };
        case SET_USER_TOTAL_ITEMS:
            return {
                ...state,
                totalItems: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;