import  React ,{useRef, useEffect} from 'react';
import ErrorIcon from '../assets/icons/ErrorIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import './ErrorComponent.css';
import BackIcon from '../assets/icons/BackIcon';


const ErrorComponent = (
    props
) => {

    const resetErrorBoundary = props.resetErrorBoundary;
    const error = props.error;
    const errorMessage = error ? error.toString(): 'An unknown error occurred';
    const { pathname } = useLocation();
    const originalPathname = useRef(pathname);
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname !== originalPathname.current && resetErrorBoundary) {
            resetErrorBoundary();
        }
    }, [pathname, resetErrorBoundary]);

    return (
        <div className = 'error_component'>
            <h1 className='error_heading'><ErrorIcon/> Something Went Wrong </h1>
            <div>A client error occurred, please try again later</div>

            {process.env.NODE_ENV !== 'production' && error && (
                <details>
                    <h2>{(errorMessage.toString())}</h2>
                </details>
            )}
            <div>
                <button
                    onClick = {()=>navigate(-1)}
                    className='error_back_button'
                >
                  <BackIcon/>
                    Back
                </button>
            </div>
        </div>
    );
};

export default ErrorComponent;