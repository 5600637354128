import React, {useState, useEffect} from 'react';
import {
    useDataProvider,
    TopToolbar,
    CreateButton,
    Title,
    TextInput,
    SimpleForm,
    Edit,
    ShowButton,
    EditButton,
    BooleanInput,
    useRedirect,
    useNotify,
    NumberInput,
    SimpleShowLayout,
    Show,
    TextField,
    useRecordContext,
    Create,
    SaveButton,
    Toolbar,
    Button,
    Confirm,
    useRefresh,
    FunctionField
}from 'react-admin';
import {useParams} from 'react-router-dom';
import Loader from '../components/Loader';
import NotFound from '../components/Profile/NotFound/NotFound';
import {useDispatch, useSelector} from 'react-redux';
import { BRAND_DETAILS } from './Brands';
import { setFaqDetails } from '../actions/faqActions';
import { setChannelData } from '../actions/channelActions';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '../assets/icons/DeleteIcon';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const useFetchFaqs = () => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const faqs = useSelector((state) => state.faq.details || []);
    const [loading, setLoading] = useState(faqs.length===0);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');

    const fetchFaqs = async () => {
        try {
            // Fetch channels first
            setLoading(true);

            const channelParams = {
                pagination: { page: 1 },
            };
            
            const { data } = await dataProvider.getList('channels', channelParams);

            setChannelData(data)

            // Now, use the channels to fetch FAQs
            const faqPromises = data.map(async (channel) => {
                const faqParams = {
                    pagination: { page: 1 },
                    filter: { slug: channel.slug },
                };
                return dataProvider.getList('faqs', faqParams)
                    .then(response => response.data)
                    .catch(error => {
                        console.error(`Error fetching FAQs for brand: ${channel?.brandCode}, channel: ${channel?.slug}`, error);
                        return [];
                    });
            });

            const brandFaqs = await Promise.all(faqPromises);
            const allFaqs = brandFaqs.flat();
            dispatch(setFaqDetails(allFaqs));
            setLoading(false);
        } catch(error) {
            console.error('error')
        }
    }

    useEffect(()=>{
        fetchFaqs();
    },[dataProvider, selectedBrand])

    return {loading};
}

const FaqListActions = () => (
    <TopToolbar>
        <CreateButton label='Create' />
    </TopToolbar>
);


const CustomDeleteButton = ({record}) => {
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const dispatch = useDispatch();
    const faqs = useSelector(state => state.faq.details || []);


    const handleDelete = async () => {
        setOpen(false);
        try {
            await dataProvider.delete('faqs', { id: record['@id'] });
            const updatedFaqs = faqs.filter(faq => faq.id !== record.id);
            dispatch(setFaqDetails(updatedFaqs));
            notify('Faq deleted successfully', 'info');
            redirect('/faqs');
            refresh();
        } catch (error) {
            notify(`Error deleting resource: ${error.message}`, 'warning');
        }
    };
    return (
        <>
            <Button label="Delete" onClick={() => setOpen(true)} style = {{marginLeft:'10px'}}>
                <DeleteIcon />
            </Button>

            <Confirm
                isOpen={open}
                loading={false}
                title="Are you sure you want to delete this item?"
                content="This action is irreversible."
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

export const FaqList = () => {
    const {loading} = useFetchFaqs();
    const faqs = useSelector(state => state.faq.details || []);

    if (loading) return <Loader/>;

    if(!faqs || faqs.length ===0) {
        return(<>            
            <div className ='faq_headline'>
                <p className='faq_description'>You need assistance in technical, editorial or administrative cases regarding Retail Content Manager? <br/> Please have a look on our FAQs, handouts and video tutorials for general information.</p>
                <FaqListActions/>
            </div>                
            <NotFound resource='faqs' />
             </>)}

    return (
        <div className = 'customGrid_dealership'>
            <Title title = 'FAQs'/>
            <h2>System Support</h2>
            <div className ='faq_headline'>
                <p className='faq_description'>You need assistance in technical, editorial or administrative cases regarding Retail Content Manager? <br/> Please have a look on our FAQs, handouts and video tutorials for general information.</p>
                <FaqListActions/>
            </div>

            <table className='dealerships_table'>
                <thead>
                    <tr className='dealershipsHead'>
                        <th>Brand</th>
                        <th>Category</th>
                        <th>Position</th>
                        <th>Language</th>
                        <th>Active</th>
                        <th>Channel</th>
                        <th>Question</th>
                        <th>Answer</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {faqs.map(faq => {
                        const brandCode = faq.brand.split('/').pop();
                        const brand = BRAND_DETAILS.find(b=>b.code===brandCode);
                        return (
                            <tr key = {faq.id} className='dealershipsRow'>
                                <td>{brand.name}</td>
                                <td>{faq.category}</td>
                                <td>{faq.position}</td>
                                <td>{faq.language}</td>
                                <td>
                                    {faq.enabled ? <CheckIcon  /> : <CloseIcon />}
                                </td>
                                <td>{faq.channel.split('/').pop()}</td>
                                <td>{faq.question}</td>
                                <td>{faq.answer}</td>
                                <td className='crud_buttons'>
                                    <EditButton className='edit-button' basepath='/faqs' record={faq}/>
                                    <ShowButton className='show-button' basepath = '/faqs' record = {faq}/>
                                    <CustomDeleteButton record = {faq} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton/>
    </Toolbar>
);

export const FaqEdit = props => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const {loading} = useFetchFaqs();
    const { id } = useParams()

    const faqs = useSelector(state => state.faq.details);
    const faq = faqs ? faqs.find(faq => faq.id === id) : null;

    if(loading) return <Loader/>;
    if(!faq) return <NotFound resource='faqs' />

    const onSuccess = async (data) => {
        try {
            const faqParams = {
                id:data.id,
                data
            }
            await dataProvider.update('faqs', {...faqParams});
            const updatedFaqs = faqs.map((faq) => {
                if(faq.id === data.id){
                    return {...faq, ...data};
                }
                return faq;
            });
            dispatch(setFaqDetails(updatedFaqs));
            notify('Faq updated successful', 'info');
            redirect(`/faqs/${data.id}/show`)
        }catch(error){
            console.error('Error updating faqs:',error);
            notify(`Error updating faq: ${error.message}`, 'warning');
        }
    }

    const onError = (error) => {
        console.error('Error updating faq:',error);
        notify(`Error updating ${error.message}`,'warning');
    }

    return (
        <Edit {...props} mutationOptions={{onSuccess, onError}} title = 'FAQ Edit'>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <TextInput fullWidth source='category' label='Category' />
                <TextInput fullWidth source='language' label = 'Language' />
                {/* <TextInput fullWidth source ='position' label = 'Position' /> */}

                <NumberInput
                    source='position'
                    label='Position'
                    fullWidth
                    parse={value => isNaN(parseInt(value, 10)) ? undefined : parseInt(value, 10)}
                />

                <BooleanInput fullWidth source = 'enabled' label = 'Active' />
                <TextInput fullWidth source= 'question' label ='Question' multiline rows = {6} />
                <TextInput fullWidth source= 'answer' label ='Answer' multiline rows = {6} />
            </SimpleForm>
        </Edit>
    )


}

// const ChannelNameField = async ({ record }) => {
//     return <span>{record.channel.split('/').pop()}</span>;
// };

const BrandNameField = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    const brandCode = record[source].split('/').pop();
    const brand = BRAND_DETAILS.find(b => b.code === brandCode);
    return <span>{brand ? brand.name : 'Unknown'}</span>;
};

export const FaqShow = props => 
        <Show {...props} title = 'FAQ Show'>
            <SimpleShowLayout className='simpleShowLayout'>
                <BrandNameField source='brand' label= 'Brand'/>
                <TextField source = 'category' />
                <FunctionField
                    label="Channel"
                    render={record => `${record.channel.split('/').pop()}`}
                />
                <TextField source = 'enabled' label='Active'/>
                <TextField source = 'language' />
                <TextField source = 'position' />
                <TextField source = 'question' />
                <TextField source = 'answer' />
            </SimpleShowLayout>
        </Show>


export const FaqCreate = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const [language, setLanguage] = useState('');
    const [channelOptions, setChannelOptions] = useState([]);

    const [category, setCategory] = useState('');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [position, setPosition] = useState(0);
    const [enabled, setEnabled] = useState(false);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');
    const getChannels = async () =>{  const channelParams = {
            pagination: { page: 1},
        };
        
        const { data } = await dataProvider.getList('channels', channelParams);
        setChannelOptions(data);
    }
    const [selectedChannel, setSelectedChannel] = useState('');

    useEffect(()=> {
        getChannels()
    },[selectedBrand])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            brand: '/brands/'+selectedBrand,
            language,
            category,
            question,
            answer,
            channel: `/brands/${selectedBrand}/channels/${selectedChannel}`,
            position: parseInt(position, 10),
            enabled,
        };

        try {
            await dataProvider.create('faqs', { data: formData });
            notify('Faq created successfully', 'info');
            redirect('/faqs');
        } catch (error) {
            notify(`Error: ${error.message}`, 'error');
        }
    };


    return (
        <Create>
            <form className='form_faq' onSubmit={handleSubmit}>
                <div className='form_group'>
                    <input
                        className='full-width'
                        type="text"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        placeholder="Language"
                    />
                </div>
                <div className='form_group'>
                    <input
                        className='full-width'
                        type="text"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        placeholder="Category"
                    />
                </div>

                <div className='form_group'>
                    <textarea
                        className='full-width'
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        placeholder="Question"
                        rows="4"
                    />
                </div>

                <div className='form_group'>
                    <textarea
                        className='full-width'
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        placeholder="Answer"
                        rows="4"
                    />
                </div>

                <div className='form_group'>
                    Position:
                    <input
                        type = 'number'
                        className='full-width'
                        value={position}
                        onChange = {(e)=>setPosition(e.target.value)}
                        min="0"
                        max="1000"
                        step="1"
                    />
                </div>

                <div className='form_group faq_checkbox_div'>
                    <input
                        type = 'checkbox'
                        className='faq_checkbox'
                        onChange = {(e)=>setEnabled(e.target.checked)}
                        placeholder ='Active'
                        checked={enabled}
                    />
                    Active
                </div>
                    <div className='form_group'>
                        <select
                            className='channels_dropdown full-width channels_dropdown_faq'
                            placeholder='Channel'
                            value={selectedChannel}
                            onChange={(e) => setSelectedChannel(e.target.value)}>
                            <option value="">Select a Channel</option>
                            {channelOptions.map(channel => (
                                <option key={channel.id} value={channel?.slug}>{channel.title}</option>
                            ))}
                        </select>
                    </div>
                <button className='save_button save_button_faq'
                    type='submit'
                >
                    <SaveIcon fontSize='small'/>
                    <div className='save_text'>SAVE</div>
                </button>
            </form>
        </Create>
    );
};


export const FaqDelete = ({record}) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const faqs = useSelector(state => state.faq.details || []);

    const handleDelete = async () => {
        try {
            await dataProvider.delete('faqs', {id: record.id});
            const updatedFaqs = faqs.filter(faq => faq.id !== record.id);
            dispatch(setFaqDetails(updatedFaqs));
            notify('Faq deleted successfully');
            redirect('/faqs');
        }catch(error){
            notify(`Error deleting faq: ${error.message}`,'warning');
            console.error('Error deleting faq', error);
        }
    };
    return <Button onClick = {handleDelete} > Delete </Button>;
}