import { SET_CAR_TYPE_DETAILS, SET_SHOULD_FETCH_CAR_TYPES, SET_CAR_TYPE_TOTAL_ITEMS} from '../actions/carTypeActions';

const initialState = {
    details:null,
    shouldFetchCarTypes:false,
    totalItems:0,
};

const carTypeReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CAR_TYPE_DETAILS:
            return{
                ...state,
                details:action.payload
            };
        case SET_SHOULD_FETCH_CAR_TYPES:
        return {
            ...state,
            shouldFetchCarTypes: action.payload,
        };
        case SET_CAR_TYPE_TOTAL_ITEMS:
        return {
            ...state,
            totalItems: action.payload,
        };
        default:
            return state;
    }
};

export default carTypeReducer;