export const SET_USER_DATA='SET_USER_DATA';
export const setUserData = (data) =>({
    type:SET_USER_DATA,
    payload:data
});

export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const setUserDetails = (user) => ({
    type: SET_USER_DETAILS,
    payload: user,
});

export const SET_SHOULD_FETCH_USER_RESOURCES = 'SET_SHOULD_FETCH_USER_RESOURCES';
export const setShouldFetchUserResources = (user) => ({
    type: SET_SHOULD_FETCH_USER_RESOURCES,
    payload: user,
});

export const SET_USER_ROLES = 'SET_USER_ROLES';
export const setUserRoles = (roles) => ({
    type: SET_USER_ROLES,
    payload: roles,
});

export const SET_USER_COUNTRIES = 'SET_USER_COUNTRIES';
export const setUserCountries = (countries) => ({
    type: SET_USER_COUNTRIES,
    payload: countries,
});

export const SET_USER_CHANNELS = 'SET_USER_CHANNELS';
export const setUserChannels = (channels) => ({
    type: SET_USER_CHANNELS,
    payload: channels,
});

export const SET_USER_DEALERSHIPS = 'SET_USER_DEALERSHIPS';
export const setUserDealerships = (dealerships) => ({
    type: SET_USER_DEALERSHIPS,
    payload: dealerships,
});

export const SET_USER_DEALERSHIP_GROUPS = 'SET_USER_DEALERSHIP_GROUPS';
export const setUserDealershipGroups = (dealershipGroups) => ({
    type: SET_USER_DEALERSHIP_GROUPS,
    payload: dealershipGroups,
});

export const SET_USER_TOTAL_ITEMS = 'SET_USER_TOTAL_ITEMS';
export const setUserTotalItems = (totalItems) => ({
    type: SET_USER_TOTAL_ITEMS,
    payload: totalItems,
});