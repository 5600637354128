import React from 'react';

const CarTypesIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            traffic-small
        </title>
        <path d="M9,5.5 L8.5,5.5 L3.5,5.5 L0.5,8.5 L0.5,11.75 C0.5,12.1642136 0.835786438,12.5 1.25,12.5 L1.75,12.5 C2.16421356,12.5 2.5,12.1642136 2.5,11.75 L2.5,11.5 L2.5,11.5 L2.5,11 M0.5,9.5 L2.5,9.5 M15.75,8.75 L14.5,7.5 L8.5,7.5 L4.45454545,11.5 L4.4932438,15.7568179 C4.49698504,16.1683544 4.83165934,16.5 5.24321281,16.5 L6,16.5 C6.27614237,16.5 6.5,16.2761424 6.5,16 L6.5,15.5 L6.5,15.5 L6.5,15 M4.5,12.5 L7,12.5 M8.5,14.5 L8.5,19.5 C8.5,20.0522847 8.94771525,20.5 9.5,20.5 L10.5,20.5 C11.0522847,20.5 11.5,20.0522847 11.5,19.5 L11.5,19.5 L11.5,19.5 L20.5,19.5 C20.5,20.0522847 20.9477153,20.5 21.5,20.5 L22.5,20.5 C23.0522847,20.5 23.5,20.0522847 23.5,19.5 L23.5,14.5 L23.5,14.5 L19.5,10.5 L12.5,10.5 L8.5,14.5 Z M8.5,15.5 L12,15.5 M20,15.5 L23.5,15.5 M9.5,13.5 L22.5,13.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default CarTypesIcon;