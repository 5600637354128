import React from 'react';

const BrandsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            system-favorites-small
        </title>
        <path d="M11.5,5.7 L13.2,9.2 L17.1,9.8 L14.3,12.5 L15,16.3 L11.6,14.5 L8.2,16.3 L8.9,12.5 L5.9,9.7 L9.8,9.1 L11.5,5.7 Z M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default BrandsIcon;
