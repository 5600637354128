import React, {useState, useEffect } from 'react';
import { AppBar, UserMenu, useDataProvider } from 'react-admin';
import { Typography } from '@mui/material';
import MyLogoutButton from './MyLogoutButton';
import ProfileButton from './components/Profile/ProfileButton';
import { BRAND_DETAILS } from './pages/Brands';
import BrandSwitcher from './components/BrandSwitcher';
import { setAdminBrands } from './actions/adminActions'
import { getCookie } from './helpers';
import { setBrand } from './actions/brandSwitcherActions';
import {useDispatch, useSelector} from 'react-redux';

const MyUserMenu = () => <UserMenu>
        <MyLogoutButton />
        <ProfileButton/>
    </UserMenu>;

const MyAppBar = (props) =>{ 
    const dispatch = useDispatch();
    const [brandsToSwitch, setUserBrandToSwitch] = useState([]);
    const dataProvider = useDataProvider();
    const adminSub = getCookie('sub')
    const selectedBrand = useSelector(state => state.brand.brand);

    if(!adminSub){
       return  window.location.href = '/login';
    } else{

    const getUserBrands = async()=> {
        const me =  await dataProvider.getOne('users', { id: adminSub});
        const userBrandCodes = me.data.brands.map(brand => brand.split('/').pop());

        if(userBrandCodes.length === 1) {
            dispatch(setBrand(userBrandCodes[0]))
        }
        dispatch(setAdminBrands(userBrandCodes));
        setUserBrandToSwitch(BRAND_DETAILS.filter(brand => userBrandCodes.includes(brand.code)));
        let link = document.querySelector('link[rel~=icon]');
        if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = `/${selectedBrand}-favicon.ico`;
    }

    useEffect(()=>{
        getUserBrands();
    },[])

    return (
    <AppBar
        toolbar={<></>}
        sx={{
            '& .RaAppBar-title': {
                flex: 1,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
        }}
        userMenu={<MyUserMenu/>}
        {...props}
    >
        <Typography
            variant='h6'
            color='inherit'
            className=''
            id='react-admin-title'
            sx={{
                flex: 1
            }}
        />
        {brandsToSwitch.length > 1 && <BrandSwitcher brands={brandsToSwitch} />}
    </AppBar>
)}};

export default MyAppBar;