import { SET_CHANNEL_DATA, SET_CHANNEL_DETAILS } from '../actions/channelActions.js';

const initialState = {
    data:null,
    details:null,
};

const channelReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CHANNEL_DATA:
            return {
                ...state,
                data: action.payload
            };
        case SET_CHANNEL_DETAILS:
            return{
                ...state,
                details:action.payload
            }
        default:
            return state;
    }
};

export default channelReducer;