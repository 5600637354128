import { getCookie, setCookie, removeCookie } from './helpers';
import jwtDecode from 'jwt-decode';


const refreshAuth = async() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getCookie('token');
    const refreshToken= getCookie('refreshToken');


    if(!token && !refreshToken) {
        window.location.href = '/login';
    }

    const jwtDecodedToken = jwtDecode(token)
    const currentTime = new Date().getTime() / 1000;

    if (jwtDecodedToken.exp < currentTime) {
            return fetch(`${apiUrl}/authentication/cognito/refresh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    refresh_token: refreshToken
                })
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    }
                })
                .then(response => {
                    if (typeof response.token !== 'undefined') {

                        const currentTimeEpocSec = Date.now() / 1000;
                        const jwtDecodedToken = jwtDecode(response.token);
                        const duration = jwtDecodedToken.exp - currentTimeEpocSec;
                        const hours = duration / 3600;
                        setCookie('token', response.token, hours)
                        setCookie('refreshToken', refreshToken, hours + 4);
                    }
                })
                .catch(e => {
                    if (e.message === 'Unauthorized') {
                        removeCookie('token');
                        removeCookie('refreshToken');
                        return window.location.href = '/login';
                    }

                    throw e;
                });
    }

    return Promise.resolve();
}

export default refreshAuth;