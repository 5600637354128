import React from 'react';
import { useRecordContext } from 'react-admin';

export const CustomImageField = (props) => {
    const { source, label } = props;
    const record = useRecordContext(props);
    const imageUrl = record[source];

    if (!imageUrl) {
        return <div>No {label}</div>;
    }

    return (
            <img
                src={imageUrl}
                alt={label}
                className='style-image'
                style={{ width: 'auto', height: '270px', objectFit: 'cover' , textAlign:'left'}}
            />
    );
};

CustomImageField.defaultProps = { addLabel: true};