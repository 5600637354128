import React, { useEffect } from 'react';
import { auth } from './GRP';
import { setCookie } from './helpers';
import jwtDecode from 'jwt-decode';
import Loader from './components/Loader';

const ConnectView = () => {
    const grpAuthorize = async () => {
        try{
            const user = await auth.getIdTokenPayload();
            if (!user) {
                await auth.authorize();
            }
            const token = await auth.getToken();

            if (token && token.accessToken) {
                const currentTimeEpocSec = Date.now() / 1000;
                const jwtDecodedToken  = jwtDecode(token.accessToken);
                const duration = jwtDecodedToken.exp = currentTimeEpocSec;
                const hours = duration / 3600;

                const cognitoGroups = jwtDecodedToken['cognito:groups'];
                const isAdmin = cognitoGroups.includes('NEWRETAIL_ADMIN')
                localStorage.setItem('permissions', cognitoGroups);
                localStorage.setItem('isAdmin', isAdmin);

                
                setCookie('sub', jwtDecodedToken.sub, hours);
                setCookie('token', token.accessToken, hours);
                setCookie('refreshToken', token.refreshToken, hours + 4);
                window.location.href = '/brands';
            } else {
                console.log('waiting for the user token')
            }
        }catch(error){
            console.error('Error in authorization process:', error);
        }
    }

    useEffect(() => {
        grpAuthorize();
    },[]);

    return (
        <div className='login_div'>
            <Loader/>
        </div>
    );
};

export default ConnectView;