export const SET_CAR_MODEL_UUID = 'SET_CAR_MODEL_UUID';
export const setCarModelUuid = (uuid) => ({
    type: SET_CAR_MODEL_UUID,
    payload: uuid
});

export const SET_CAR_MODEL_DATA='SET_CAR_MODEL_DATA';
export const setCarModelData = (data) =>({
    type:SET_CAR_MODEL_DATA,
    payload:data
})

export const SET_LOADING = 'SET_LOADING';
export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading,
});

export const SET_CAR_MODEL_DETAILS = 'SET_CAR_MODEL_DETAILS';
export const setCarModelDetails = (carModel) => ({
    type: SET_CAR_MODEL_DETAILS,
    payload: carModel,
});

export const SET_SHOULD_FETCH_CAR_MODELS = 'SET_SHOULD_FETCH_CAR_MODELS';
export const setShouldFetchCarModels = (shouldFetchCarModels) => ({
    type: SET_SHOULD_FETCH_CAR_MODELS,
    payload: shouldFetchCarModels,
});

export const SET_CAR_MODEL_TOTAL_ITEMS = 'SET_CAR_MODEL_TOTAL_ITEMS';
export const setCarModelTotalItems = (totalItems) => ({
    type: SET_CAR_MODEL_TOTAL_ITEMS,
    payload: totalItems,
});