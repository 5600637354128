const black = '#000';
const white = '#fff';
const lightGray = '#f2f2f2';
const gray = '#ccc';
const darkGray = '#333';
const signalRed = '#fd2c4e';

export const audiTheme = {
    typography: {
        fontFamily: 'AudiTypeScreenNormal',
        fontSize:15,
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: white,
                    backgroundColor: black,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    backgroundColor: black,
                    '&:hover': {
                        backgroundColor: darkGray,
                    },
                },
                root: {
                    '&.RaConfirm-confirmPrimary': {
                        color: signalRed
                    },
                },
                textPrimary:{
                    color: black
                }
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    '&.RaMenuItemLink-active': {
                        color: black,
                        borderLeft: `4px solid ${black}`,
                        fontWeight: 600,
                    },
                    '&.RaMenuItemLink-active > .MuiListItemIcon-root > .MuiSvgIcon-root': {
                        color: black,
                    }
                },
            },
        },
        MuiInputLabel:{
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: black,
                    }
                }
        }},
        MuiPaper:{
            styleOverrides: {
                root: {
                    '&.RaNotification-error, &.RaNotification-warning': {
                        backgroundColor: signalRed,
                    }
                },
        }},
        MuiScopedCssBaseline: {
            styleOverrides: {
                root: {
                    backgroundColor: lightGray
                },
            },
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    backgroundColor: lightGray,
                    position:'sticky',
                    height:'100vh',
                    top:'0',
                    left:'0',
                    overflowY:'auto',

                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-head': {
                        backgroundColor: gray
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: black
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                colorPrimary: {
                    '&.Mui-checked': {
                        color: black,
                        '& + .MuiSwitch-track': {
                            backgroundColor: black,
                        },
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgroundColor: gray,
                  '&:hover': {
                    backgroundColor: gray,
                },
                }
              }
            },
          },
    },
};