export const SET_FAQ_DATA='SET_FAQ_DATA';
export const setFaqData = (data) =>({
    type:SET_FAQ_DATA,
    payload:data
})

export const SET_FAQ_DETAILS = 'SET_FAQ_DETAILS';
export const setFaqDetails = (faq) => ({
    type: SET_FAQ_DETAILS,
    payload: faq,
});

export const SET_SHOULD_FETCH_FAQS = 'SET_SHOULD_FETCH_FAQS';
export const setShouldFetchFaqs = (shouldFetchFaqs) => ({
    type: SET_SHOULD_FETCH_FAQS,
    payload: shouldFetchFaqs,
})


export const SET_FAQ_CHANNELS = 'SET_FAQ_CHANNELS';
export const setFaqChannels = (channels) => ({
    type: SET_FAQ_CHANNELS,
    payload: channels,
})