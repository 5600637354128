import {store} from '../../store';

class ChannelEndpointResolver {
    supports(resource) {
        return resource === 'channels';
    }

    getListUri(){
        const state = store.getState();
        return `/brands/${state.brand.brand}/channels`;
    }

    getOneUri(_resource, params) {
        const state = store.getState();
        return `/brands/${state.brand.brand}/channels/${params.id}`;
    }

    getUpdateUri(_resource, params){
        const state = store.getState();
        return `/brands/${state.brand.brand}/channels/${params.id}`;
    }

    getCreateUri(){
        const state = store.getState();
        return `/brands/${state.brand.brand}/channels`;
    }
}

export default ChannelEndpointResolver;