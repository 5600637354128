import React, {useState, useEffect} from 'react';
import {
    useDataProvider,
    TopToolbar,
    Title,
    ShowButton,
    SimpleShowLayout,
    TextField,
    Show,
} from 'react-admin';
import Loader from '../components/Loader';
import {useSelector, useDispatch} from 'react-redux';
import Pagination from '../components/Pagination';
import { setPage } from '../actions/pageActions';
import { setAssetOverridesTotalItems, setAssetOverridesDetails} from '../actions/assetOverridesActions';

const useFetchAssetOverrides = (itemsPerPage = 30) => {
    const page = useSelector(state => state.page.page || 1);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');

    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const assetOverrides = useSelector(state => state.assetOverrides.details);
    const [loading, setLoading] = useState(!assetOverrides || assetOverrides.length === 0);

    const fetchAssetOverrides = async () => {
        setLoading(true);

        try {
            const assetOverridesParams = {
                pagination: { page: page, perPage: itemsPerPage },
                sort: { field: 'id', order: 'ASC' },
                filter: { brand: selectedBrand },
            };
            const { data, total } = await dataProvider.getList('assetOverrides', assetOverridesParams);

            dispatch(setAssetOverridesTotalItems(total));
            dispatch(setAssetOverridesDetails(data));
        } catch (error) {
            console.error('Error fetching AssetOverrides:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        fetchAssetOverrides();
    },[page, selectedBrand])

    useEffect(()=>{
        if (!assetOverrides || assetOverrides.length === 0 ){
            fetchAssetOverrides();
        }else {
            setLoading(false);
        }
    },[])

    return loading;
}

const AssetOverridesListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

export const AssetOverridesList = () =>{
    const dispatch = useDispatch();
    const loadingAssetOverrides = useFetchAssetOverrides();
    const assetOverrides = useSelector(state => state.assetOverrides.details || []);
    const page = useSelector(state => state.page.page);
    const totalItems = useSelector(state=>state.assetOverrides.totalItems || 0);
    const selectedBrand = useSelector(state => state.brand.brand);

    const handlePageChange = (newPage) => {
        dispatch(setPage(newPage))
    };

    useEffect(() => {
        if(selectedBrand){
            dispatch(setPage(1));
        }
    }, [selectedBrand, dispatch]);

    if (loadingAssetOverrides) return <Loader/>;

    return (
        <div className = 'customGrid_dealership'>
            <Title title = 'AssetOverrides' />
            <AssetOverridesListActions />
            <table className='dealerships_table'>
                <thead>
                    <tr className='dealershipsHead'>
                        <th>Type</th>
                        <th>Device Number</th>
                        <th>Url</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {assetOverrides.map(assetOverride => {

                        return (
                            <tr key = {assetOverride.id} className='dealershipsRow'>
                                <td>{assetOverride.type}</td>
                                <td>{assetOverride.deviceNumber}</td>
                                <td>
                                    <span style={{width: '50vw',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: 'block'}}
                                    >
                                        {assetOverride.url}
                                    </span>
                                </td>
                                <td className='crud_buttons'>
                                    <ShowButton className='show-button' basepath='/assetOverrides' record={assetOverride} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Pagination total={totalItems} current={page} onChange={handlePageChange} />
        </div>
    )
}

export const AssetOverridesShow= (props) => {
    return (
        <Show {...props} title='Asset Override Show' >
            <SimpleShowLayout>
                <TextField source ='type' label = 'Type'/>
                <TextField source='deviceNumber' label = 'Device Number' />
                <TextField source='url' label = 'Url' />
                <TextField source = 'state' label = 'State' />
            </SimpleShowLayout>
        </Show>
    )
}

