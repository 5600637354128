
import { SET_MODULE_TYPE_DATA, SET_MODULE_TYPE_DETAILS, SET_MODULE_TYPE_COUNTRY_RESTRICTIONS } from '../actions/moduleTypeActions.js';

const initialState = {
    data:null,
    details:null,
    countryRestrictions:null,
};

const moduleTypeReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_MODULE_TYPE_DATA:
            return {
                ...state,
                data: action.payload
            };
        case SET_MODULE_TYPE_DETAILS:
            return{
                ...state,
                details:action.payload
            };
        case SET_MODULE_TYPE_COUNTRY_RESTRICTIONS:
            return{
                ...state,
                countryRestrictions:action.payload,
            };
        default:
            return state;
    }
};

export default moduleTypeReducer;