import queryString from 'query-string';
// import {store} from '../../store';

class RoleEndpointResolver {
    supports(resource) {
        return resource === 'roles';
    }
    getOneUri(_resource, params) {
        const id = params.id.split('/').pop();
        return `/roles/${id}`

    }
    getUpdateUri(_resource, params){
        return `${params.id}`
    }

    getListUri (_resource, params) {
        let name ='';
        const {filter, pagination} = params;
        const queryParams = {
            ...filter,
            page:pagination.page,
            perPage: pagination.perPage,
        }

        if (params.filter?.name){
            name = params.filter?.name?.charAt(0).toUpperCase()+ params.filter.name.slice(1);
            queryParams.name=name
        }

        return `/roles?${queryString.stringify(queryParams)}`
    }

    getDeleteUri(_resource, params){
        return `${params.id}`
    }
}

export default RoleEndpointResolver;