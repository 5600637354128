import {store} from  '../../store' ;
import queryString from 'query-string';

class CarTypeEndpointResolver {
    supports(resource) {
        return resource === 'carTypes';
    }

    getOneUri(_resource, params) {
        return params.meta.id;
    }

    getUpdateUri(_resource, params) {
        return params.id;
    }

    getCreateUri = (_resource, params) => {
        const carModelUuid = params.data.model;
        return `${carModelUuid}/car-types`;
    };

    getListUri = (_resource, params) => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/car-types?${queryString.stringify(params.pagination)}`;
    };

    getDeleteUri = (_resource, params) => {
        return params.id;    
    };

}

export default CarTypeEndpointResolver;
