
import { SET_DEALERSHIP_COUNTRY_NAMES, SET_DEALERSHIP_DATA, SET_DEALERSHIP_DEALERSHIP_GROUPS, SET_DEALERSHIP_DETAILS, SET_DEALERSHIP_TOTAL_ITEMS, SET_DEALERSHIP_SHOW_DETAILS } from '../actions/dealershipActions.js';

const initialState = {
    data:null,
    details:null,
    dealershipGroups:{},
    countryNames:{},
    totalItems:0,
    showDetails:{},
};

const dealershipReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_DEALERSHIP_DATA:
            return {
                ...state,
                data: action.payload
            };
        case SET_DEALERSHIP_DETAILS:
            return{
                ...state,
                details:action.payload
            }
        case SET_DEALERSHIP_DEALERSHIP_GROUPS:
            return{
                ...state,
                dealershipGroups: action.payload
            }
        case SET_DEALERSHIP_COUNTRY_NAMES:
            return{
                ...state,
                countryNames: action.payload
            }
        case SET_DEALERSHIP_TOTAL_ITEMS:
            return{
                ...state,
                totalItems: action.payload
            }

        case SET_DEALERSHIP_SHOW_DETAILS:
            return{
                ...state,
                showDetails: action.payload
            }
        default:
            return state;
    }
};

export default dealershipReducer;