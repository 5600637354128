import { fetchUtils } from 'react-admin';
import apiEndpoint from './services/ApiEndpointResolver';
import dataTransformer from './services/ApiDataTransformer';
import {getCookie} from './helpers';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
        options.headers = new Headers({ 'Content-Type': 'application/ld+json' });
    }
    options.headers.set('Authorization', `Bearer ${getCookie('token')}`);

    try {
        const { status, headers, body, json } = await fetchUtils.fetchJson(url, options);
        return { status, headers, body, json };
    } catch ({ status, body }){
        if (status === 400) {
            throw new Error('The request could not be understood by the server due to malformed syntax. Please check your input and try again.');
        } else if (status === 401) {
            throw new Error('You are not authorized to access this resource. Please log in and try again.');
        } else if (status === 403) {
            throw new Error('You do not have permission to access this resource. Contact your administrator if you believe this is an error.');
        } else if (status === 404) {
            throw new Error('The requested resource could not be found. Please check the URL and try again.');
        } else if (status === 422) {
            throw new Error(body.detail);
        } else {
            throw new Error('A network error occurred.');
        }
    }
};

export const dataProvider = {
    getList: (resource, params) => {
        const { page } = params.pagination;
        const query = {
            page: page,
            ...params.filter
        };

        if (resource === 'permissions'){
            if (params.filter && params.filter.value) {
                query.value = params.filter.value.toUpperCase();
            }
        }

        const url = `${apiUrl}${apiEndpoint.getListUri(resource, params, query)}`;
        return httpClient(url).then(({ json }) => ({
            data: dataTransformer.transformCollection(json, resource, params, query),
            total: parseInt(json['hydra:totalItems'], 10)
        }));

        // throw new Error('test error');
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}${apiEndpoint.getOneUri(resource, params)}`).then(({ json }) => ({
            data: dataTransformer.transformSingle(json, resource, params),
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}${apiEndpoint.getManyUri(resource, params, query)}`;
        return httpClient(url).then(({ json }) => ({ data: dataTransformer.transformCollection(json, resource, params, query) }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            page: page,
            per_page: perPage,
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}${apiEndpoint.getManyReferenceUri(resource, params, query)}`

        return httpClient(url).then(({ json }) => ({
            data: dataTransformer.transformCollection(json, resource, params, query),
            total: parseInt(json['hydra:totalItems'], 10)
        }));
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}${apiEndpoint.getUpdateUri(resource, params)}`, {
            method: 'PATCH',
            headers: new Headers({ 'Content-Type': 'application/merge-patch+json' }),
            body: JSON.stringify(dataTransformer.transformSingleBeforeUpdate(params.data, resource, params)),
        }).then(({json}) => ({data: dataTransformer.transformSingle(json, resource, params),}));
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}${apiEndpoint.getUpdateManyUri(resource, params, query)}`, {
            method: 'PATCH',
            body: JSON.stringify(dataTransformer.transformCollectionBeforeUpdate(params.data, resource, params)),
        }).then(({ json }) => ({ data: dataTransformer.transformCollection(json, resource, params, query), }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}${apiEndpoint.getCreateUri(resource, params)}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: dataTransformer.transformSingle(json, resource, params),
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}${apiEndpoint.getDeleteUri(resource, params)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: dataTransformer.transformSingle(json, resource, params) })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}${apiEndpoint.getDeleteUri(resource, params, query)}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: dataTransformer.transformCollection(json, resource, params, query), }));
    }
};
