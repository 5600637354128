import { SET_ADMIN_BRANDS, SET_ADMIN_SUB} from '../actions/adminActions';

const initialState = {
    adminSub:'',
    adminBrands:[],
};

const adminReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ADMIN_SUB:
            return{
                ...state,
                details:action.payload
            };
        case SET_ADMIN_BRANDS:
        return {
            ...state,
            shouldFetchCarTypes: action.payload,
        };
        default:
            return state;
    }
};

export default adminReducer;