export default class UserDataTransformer {
    supports(resource) {
        return resource === 'users' ;
    }
    transformCollection(data) {
        return data['hydra:member'].map(resource => ({ ...resource, id: resource['sub'] }));
    }

    transformSingle(data) {
        return { ...data, id: data['sub'] };
    }
}
