
import { SET_CAR_MODEL_UUID, SET_CAR_MODEL_DATA, SET_LOADING, SET_CAR_MODEL_DETAILS, SET_SHOULD_FETCH_CAR_MODELS, SET_CAR_MODEL_TOTAL_ITEMS} from '../actions/carModelActions';

const initialState = {
    carModelUuid: null,
    data:null,
    details:null,
    isLoading:true,
    totalItems:0,
};

const carModelReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CAR_MODEL_UUID:
            return {
                ...state,
                carModelUuid: action.payload
            };
        case SET_CAR_MODEL_DATA:
            return {
                ...state,
                data: action.payload
            };
        case SET_LOADING:
            return{
                ...state,
                isLoading: action.payload
            };
        case SET_CAR_MODEL_DETAILS:
            return{
                ...state,
                details:action.payload
            };
        case SET_SHOULD_FETCH_CAR_MODELS:
            return {
                ...state,
                shouldFetchCarModels: action.payload,
            };
        case SET_CAR_MODEL_TOTAL_ITEMS:
            return {
                ...state,
                totalItems: action.payload,
            };
        default:
            return state;
    }
};

export default carModelReducer;