export default class DealershipDataTransformer {
    supports(resource) {
        return resource === 'dealerships' ;
    }
    transformCollection(data) {
        return data['hydra:member'].map(resource => ({ ...resource, id: resource['kvps'] }));
    }

    transformSingle(data) {
        return { ...data, id: data['kvps'] };
    }
}
