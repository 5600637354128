export const SET_DEVICE_DATA='SET_DEVICE_DATA';
export const setDeviceData = (data) =>({
    type:SET_DEVICE_DATA,
    payload:data
})

export const SET_DEVICE_DETAILS = 'SET_DEVICE_DETAILS';
export const setDeviceDetails = (device) => ({
    type: SET_DEVICE_DETAILS,
    payload: device,
});

export const SET_DEVICE_MODULE_TYPES = 'SET_DEVICE_MODULE_TYPES';
export const setDeviceModuleTypes = (moduleTypes) => ({
    type: SET_DEVICE_MODULE_TYPES,
    payload: moduleTypes,
});

export const SET_DEVICE_DEALERSHIPS = 'SET_DEVICE_DEALERSHIPS';
export const setDeviceDealerships = (dealerships) => ({
    type: SET_DEVICE_DEALERSHIPS,
    payload: dealerships,
});

export const SET_DEVICE_OWNERS = 'SET_DEVICE_OWNERS';
export const setDeviceOwners = (owners) => ({
    type: SET_DEVICE_OWNERS,
    payload: owners,
});

export const SET_DEVICE_DEVICES_WITH_PLAYLISTS = 'SET_DEVICE_DEVICES_WITH_PLAYLISTS';
export const setDeviceDevicesWithPlaylists = (devicesWithPlaylists) => ({
    type: SET_DEVICE_DEVICES_WITH_PLAYLISTS,
    payload: devicesWithPlaylists,
});

export const SET_DEVICE_DEVICES_WITH_RECENT_PLAYLISTS = 'SET_DEVICE_DEVICES_WITH_RECENT_PLAYLISTS';
export const setDeviceDevicesWithRecentPlaylists = (devicesWithRecentPlaylists) => ({
    type: SET_DEVICE_DEVICES_WITH_RECENT_PLAYLISTS,
    payload: devicesWithRecentPlaylists,
});

export const SET_DEVICE_TOTAL_ITEMS = 'SET_DEVICE_TOTAL_ITEMS';
export const setDeviceTotalItems = (totalItems) => ({
    type: SET_DEVICE_TOTAL_ITEMS,
    payload: totalItems,
});