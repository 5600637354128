import React from 'react';

const DealershipGroupsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            city-small
        </title>
        <path d="M15.5,21.5 L10.5,21.5 L10.5,16.5 L7.5,16.5 L7.5,21.5 L2.5,21.5 L2.5,2.5 L15.5,2.5 L15.5,21.5 Z M15.5,21.5 L21.5,21.5 L21.5,7.5 L15.5,7.5 M16,10.5 L19,10.5 M16,13.5 L19,13.5 M16,16.5 L19,16.5 M10.5,5.5 L12.5,5.5 L12.5,7.5 L10.5,7.5 L10.5,5.5 Z M5.5,5.5 L7.5,5.5 L7.5,7.5 L5.5,7.5 L5.5,5.5 Z M10.5,10.5 L12.5,10.5 L12.5,12.5 L10.5,12.5 L10.5,10.5 Z M5.5,10.5 L7.5,10.5 L7.5,12.5 L5.5,12.5 L5.5,10.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default DealershipGroupsIcon;
