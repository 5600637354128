import React from 'react';
import { setBrand } from '../actions/brandSwitcherActions';
import {useDispatch} from 'react-redux';
import { useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const BrandSwitcher = ({ brands }) => {
    const dispatch = useDispatch();
    const selectedBrand = useSelector(state => state.brand.brand);

    const handleBrandChange = (event) => {
        const selectedBrand = event.target.value;
        console.log('new brand====',selectedBrand)
        let link = document.querySelector('link[rel~=icon]');
        const isValid = /^[A-Z]+$/.test(selectedBrand);
        if (!isValid) {
            throw new Error('Invalid brand name');
        } else {
            link.href = `/${selectedBrand}-favicon.ico`;
        }
        dispatch(setBrand(selectedBrand));
    };
    return (
            <Select
            value={selectedBrand}
            label='Select brand'
            onChange={handleBrandChange}
            style={{backgroundColor:'white', height:'25px', marginLeft:'8px'}}
            >
                {brands.map(brand => (
                    <MenuItem key={brand.code} value={brand.code}>
                        {brand.name}
                    </MenuItem>
                ))}

            </Select>
    );
};

export default BrandSwitcher;
