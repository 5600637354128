import {
    SET_DEVICE_DATA,
    SET_DEVICE_DETAILS,
    SET_DEVICE_MODULE_TYPES,
    SET_DEVICE_DEALERSHIPS,
    SET_DEVICE_OWNERS,
    SET_DEVICE_DEVICES_WITH_PLAYLISTS,
    SET_DEVICE_DEVICES_WITH_RECENT_PLAYLISTS,
    SET_DEVICE_TOTAL_ITEMS,
  } from '../actions/deviceActions.js';

  const initialState = {
      data: null,
      details: null,
      moduleTypes: [],
      dealerships: [],
      owners: [],
      devicesWithPlaylists: [],
      devicesWithRecentPlaylists: [],
      totalItems:0,
  };

  const deviceReducer = (state = initialState, action) => {
      switch (action.type) {
          case SET_DEVICE_DATA:
              return {
                  ...state,
                  data: action.payload,
              };
          case SET_DEVICE_DETAILS:
              return {
                  ...state,
                  details: action.payload,
              };
          case SET_DEVICE_MODULE_TYPES:
              return {
                  ...state,
                  moduleTypes: action.payload,
              };
          case SET_DEVICE_DEALERSHIPS:
              return {
                  ...state,
                  dealerships: action.payload,
              };
          case SET_DEVICE_OWNERS:
              return {
                  ...state,
                  owners: action.payload,
              };
          case SET_DEVICE_DEVICES_WITH_PLAYLISTS:
              return {
                  ...state,
                  devicesWithPlaylists: action.payload,
              };
          case SET_DEVICE_DEVICES_WITH_RECENT_PLAYLISTS:
              return {
                  ...state,
                  devicesWithRecentPlaylists: action.payload,
              };
          case SET_DEVICE_TOTAL_ITEMS:
              return {
                  ...state,
                  totalItems: action.payload,
              };
          default:
              return state;
      }
  };

  export default deviceReducer;
