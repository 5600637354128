import queryString from 'query-string';
import DistributionChannelEndpointResolver from './endpointResolvers/DistributionChannelEndpointResolver';
import CarModelEndpointResolver from './endpointResolvers/CarModelEndpointResolver';
import CarTypeEndpointResolver from './endpointResolvers/CarTypeEndpointResolver';
import ModuleTypeEndpointResolver from './endpointResolvers/ModuleTypeEndpointResolver';
import UserEndpointResolver from './endpointResolvers/UserEndpointResolver';
import CountryEndpointResolver from './endpointResolvers/CountryEndpointResolver';
import ChannelEndpointResolver from './endpointResolvers/ChannelEndpointResolver';
import RoleEndpointResolver from './endpointResolvers/RoleEndpointResolver';
import DealershipEndpointResolver from './endpointResolvers/DealershipEndpointResolver';
import DealershipGroupsEndpointResolver from './endpointResolvers/DealershipGroupsEndpointResolver';
import DeviceEndpointResolver from './endpointResolvers/DeviceEndpointResolver';
import ContentPlaylistEndpointResolver from './endpointResolvers/ContentPlaylistEndpointResolver';
import AssetOverridesEndpointResolver from './endpointResolvers/AssetOverridesEndpointResolver';
import FaqEndpointResolver from './endpointResolvers/FaqEndpointResolver';

class ApiEndpointResolver {

    resolverCollection = []
    addResolver(resolver) {
        this.resolverCollection.push(resolver);
    }

    getOneUri(resource, params, query) {
        return this.findResolver('getOneUri', resource, params, query) ?? `${params.id}`
    }

    getManyUri(resource, params, query) {
        return this.findResolver('getManyUri', resource, params, query) ?? `/${resource}?${queryString.stringify(query)}`
    }

    getUpdateUri(resource, params, query) {
        return this.findResolver('getUpdateUri', resource, params, query) ?? `${params.id}`
    }

    getUpdateManyUri(resource, params, query) {
        return this.findResolver('getUpdateManyUri', resource, params, query) ?? `/${resource}?${queryString.stringify(query)}`
    }

    getCreateUri(resource, params, query) {
        return this.findResolver('getCreateUri', resource, params, query) ?? `/${resource}`
    }

    getDeleteUri(resource, params, query) {
        return this.findResolver('getDeleteUri', resource, params, query) ?? `${params.id}`
    }

    getDeleteManyUri(resource, params, query) {
        return this.findResolver('getDeleteManyUri', resource, params, query) ?? `/${resource}?${queryString.stringify(query)}`
    }

    getListUri(resource, params, query) {
        return this.findResolver('getListUri', resource, params, query) ?? `/${resource}?${queryString.stringify(query)}`
    }

    getManyReferenceUri(resource, params, query) {
        return this.findResolver('getManyReferenceUri', resource, params, query) ?? `/${resource}?${queryString.stringify(query)}`
    }

    findResolver(method, resource, params, query) {
        for(var i = 0; i < this.resolverCollection.length; i++) {
            let resolver = this.resolverCollection[i];
            if (resolver.supports(resource, params, query) && typeof resolver[method] === 'function') {
                return resolver[method](resource, params, query);
            }
        }
        return null;
    }
}

let apiEndpoint = new ApiEndpointResolver();
apiEndpoint.addResolver(new DistributionChannelEndpointResolver());
apiEndpoint.addResolver(new CarModelEndpointResolver());
apiEndpoint.addResolver(new CarTypeEndpointResolver());
apiEndpoint.addResolver(new ModuleTypeEndpointResolver());
apiEndpoint.addResolver(new UserEndpointResolver());
apiEndpoint.addResolver(new CountryEndpointResolver());
apiEndpoint.addResolver(new ChannelEndpointResolver());
apiEndpoint.addResolver(new RoleEndpointResolver());
apiEndpoint.addResolver(new DealershipEndpointResolver());
apiEndpoint.addResolver(new DealershipGroupsEndpointResolver());
apiEndpoint.addResolver(new DeviceEndpointResolver());
apiEndpoint.addResolver(new ContentPlaylistEndpointResolver());
apiEndpoint.addResolver(new AssetOverridesEndpointResolver());
apiEndpoint.addResolver(new FaqEndpointResolver());

export default apiEndpoint;
