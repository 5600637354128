import { SET_BRAND } from '../actions/brandSwitcherActions';

const initialState = {
    brand:'A',
};

const brandSwitcherReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_BRAND:
            return {
                ...state,
                brand: action.payload
            };
        default:
            return state;
    }
};

export default brandSwitcherReducer;