import './loader.scss';
import React, { Component } from 'react';
import classnames from 'classnames';

class Loader extends Component {

    render() {

        const classes = classnames({
            loader: true,
            'is-visible': this.props.loading,
            'is-grey': this.props.isGrey,
            'is-inline': this.props.isInline
        }, this.props.className);

        const iconClasses = this.props.isSmall ? 'loader-icon--is-small' : 'loader-icon';

        return (
            <div className={classes}>
                <div className={iconClasses}>
                    <div className="aui-preloader">
                        <div className="aui-preloader__spinner">
                            <div className="aui-preloader__clipper">
                                <div className="aui-preloader__circle" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Loader.defaultProps = {
    loading: true,
    isGrey: false,
    isSmall: false,
};

export default Loader;
