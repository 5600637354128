import React, {useState, useEffect} from 'react';
import {
    List,
    Datagrid,
    TextField,
    Pagination,
    CreateButton,
    TopToolbar,
    SimpleForm,
    Create,
    TextInput,
    EditButton,
    Edit,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceArrayField,
    BooleanField,
    SingleFieldList,
    ChipField,
    useRecordContext,
    required,
    SearchInput,
    Toolbar,
    SaveButton,
    Confirm,
    useRedirect,
    Button,
    useNotify,
    useRefresh,
    useDataProvider,
} from 'react-admin';
import DeleteIcon from '../assets/icons/DeleteIcon';
import Loader from '../components/Loader';

const rolesFilters = [
    <SearchInput key='name' source='name' alwaysOn placeholder='Role Name'style={{padding:'1rem 0'}}/>
];

const RoleListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const RoleEditButtonWithVisibility = ({...props}) => {
    const record = useRecordContext();
    const shouldRenderEditButton = record && record.editable;
    return shouldRenderEditButton ? <EditButton {...props} /> : null;
};



const useFetchRoles = () => {
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        const fetchRoles = async () => {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setRoles([{ id: 1, name: 'Example Role', description: 'An example role.', editable: true }]);
            }, 1000);
        };

        fetchRoles();
    }, []);

    return { loading, roles };
};


export const RolesList = (props) => {
    const { loading } = useFetchRoles();

    if(loading) return <Loader />

    return(
        <List pagination={<Pagination rowsPerPageOptions={[30]} />} actions={<RoleListActions/>} filters={rolesFilters} {...props}>
            <Datagrid bulkActionButtons={false}>
                <TextField source='name' sortable={false} />
                <TextField source='description' sortable={false} />
                <BooleanField source='editable' sortable={false} />
                <ReferenceArrayField label='Permissions' reference='permissions' source='permissions' sortable={false}>
                    <SingleFieldList linkType={false}>
                        <ChipField source='label' sortable={false} />
                    </SingleFieldList>
                </ReferenceArrayField>
                <td className='crud_buttons'>
                    <RoleEditButtonWithVisibility className='edit-button'/>
                    <CustomDeleteButton  className='show-button' {...props} />
                </td>
            </Datagrid>
        </List>
    )
};

export const RoleCreate = (props) => (
    <Create title = 'Role Create' {...props} redirect='list'>
        <SimpleForm>
            <TextInput source='name' fullWidth validate={required()} />
            <TextInput source='description' fullWidth multiline validate={required()} />
            <BooleanInput source='editable' />
            <ReferenceArrayInput source='permissions' reference='permissions' >
            <AutocompleteArrayInput
                fullWidth
                filterToQuery={(q) => ({value: q})}
                optionText='value'
                validate={required()}
            />
          </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);

const CustomDeleteButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const handleDelete = async () => {
        setOpen(false);
        try {
            await dataProvider.delete('roles', { id: record.id });
            notify('Resource deleted successfully', 'info');
            redirect('/roles');
            refresh();
        } catch (error) {
            notify(`Error deleting resource: ${error.message}`, 'warning');
        }
    };
    return (
        <>
            <Button label="Delete" onClick={() => setOpen(true)} style = {{marginLeft:'10px'}}>
                <DeleteIcon />
            </Button>

            <Confirm
                isOpen={open}
                loading={false}
                title="Are you sure you want to delete this item?"
                content="This action is irreversible."
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
            />
        </>
    );
};


const CustomToolbar = props => (
    <Toolbar style={{display:'flex', justifyContent:'space-between'}} {...props} >
        <SaveButton />
    </Toolbar>
);

export const RoleEdit = (props) => (
    <Edit title = 'Role Edit' {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} >
            <TextInput source='name' fullWidth validate={required()} />
            <TextInput source='description' fullWidth multiline validate={required()}/>
            <BooleanInput source='editable' />
            <ReferenceArrayInput source='permissions' reference='permissions' >
            <AutocompleteArrayInput
                fullWidth
                filterToQuery={(q) => ({value: q})}
                optionText='value'
                validate={required()}
            />
          </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
