import React from 'react';

const CarModelsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            car-small
        </title>
        <path d="M0.5,11.5 L0.5,19.5 C0.5,20.0522847 0.94771525,20.5 1.5,20.5 L3.5,20.5 C4.05228475,20.5 4.5,20.0522847 4.5,19.5 L4.5,18.4545455 L4.5,18.4545455 L19.5,18.5 L19.5,19.5 C19.5,20.0522847 19.9477153,20.5 20.5,20.5 L22.5,20.5 C23.0522847,20.5 23.5,20.0522847 23.5,19.5 L23.5,11.5 L23.5,11.5 L21.5,9.5 C20.8393934,8.28787879 20.3090904,7.36363636 19.9090909,6.72727273 C19.5090915,6.09090909 19.0393945,5.36363636 18.5,4.54545455 C16.3089592,4.36363636 14.1462451,4.27272727 12.0118577,4.27272727 C9.87747036,4.27272727 7.70685112,4.36363636 5.5,4.54545455 C4.9606062,5.36363636 4.49090923,6.09090909 4.09090909,6.72727273 C3.69090895,7.36363636 3.16060592,8.28787879 2.5,9.5 L0.5,11.5 Z M6.5,13.5 L7.5,12.5 L16.5,12.5 L17.5,13.5 C16.8636364,15.5 16.3636364,16.5 16,16.5 C15.4545455,16.5 8.54545455,16.5 8,16.5 C7.63636364,16.5 7.13636364,15.5 6.5,13.5 Z M2.5,9.5 L21.5,9.5 M19,12.5 L23.5,12.5 M0.5,12.5 L5,12.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);

export default CarModelsIcon;
