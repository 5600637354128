import queryString from 'query-string';
import {store} from '../../store';

class CarModelEndpointResolver {
    supports(resource) {
        return resource === 'carModels';
    }

    getOneUri(_resource, params) {
        const state = store.getState();
        return `/brands/${state.brand.brand}/car-models/${params.id}`;
    }

    getUpdateUri(_resource, params) {
        return params.id;
    }

    getListUri = (_resource, params) => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/car-models?${queryString.stringify(params.pagination)}`;

    };

    getCreateUri = () => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/car-models`;
    };

    getDeleteUri = (_resource, params) => {
        const state = store.getState();
        return `/brands/${state.brand.brand}/car-models/${params.id}`;    
    };
}

export default CarModelEndpointResolver;