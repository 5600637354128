export const SET_DEALERSHIP_GROUP_DATA='SET_DEALERSHIP_GROUP_DATA';
export const setDealershipGroupData = (data) =>({
    type:SET_DEALERSHIP_GROUP_DATA,
    payload:data
})

export const SET_DEALERSHIP_GROUP_DETAILS = 'SET_DEALERSHIP_GROUP_DETAILS';
export const setDealershipGroupDetails = (dealershipGroup) => ({
    type: SET_DEALERSHIP_GROUP_DETAILS,
    payload: dealershipGroup,
});

export const SET_DEALERSHIP_GROUP_DEALERSHIPS = 'SET_DEALERSHIP_GROUP_DEALERSHIPS';
export const setDealershipGroupDealerships = (dealerships) => ({
    type: SET_DEALERSHIP_GROUP_DEALERSHIPS,
    payload: dealerships,
});

export const SET_DEALERSHIP_GROUP_OWNERS = 'SET_DEALERSHIP_GROUP_OWNERS';
export const setDealershipGroupOwners = (owners) => ({
    type: SET_DEALERSHIP_GROUP_OWNERS,
    payload: owners,
});

export const SET_DEALERSHIP_GROUP_COUNTRIES = 'SET_DEALERSHIP_GROUP_COUNTRIES';
export const setDealershipGroupCountries = (countries) => ({
    type: SET_DEALERSHIP_GROUP_COUNTRIES,
    payload: countries,
});

export const SET_DEALERSHIP_GROUP_USERS = 'SET_DEALERSHIP_GROUP_USERS';
export const setDealershipGroupUsers = (users) => ({
    type: SET_DEALERSHIP_GROUP_USERS,
    payload: users,
});

export const SET_DEALERSHIP_GROUP_TOTAL_ITEMS = 'SET_DEALERSHIP_GROUP_TOTAL_ITEMS';
export const setDealershipGroupTotalItems = (totalItems) => ({
    type: SET_DEALERSHIP_GROUP_TOTAL_ITEMS,
    payload: totalItems,
});