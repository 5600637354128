export const SET_ADMIN_SUB = 'SET_ADMIN_SUB'
export const setAdminSub = (adminSub) => ({
    type: 'SET_ADMIN_SUB',
    payload: adminSub,
});

export const SET_ADMIN_BRANDS = 'SET_ADMIN_BRANDS'
export const setAdminBrands = (adminBrands) => ({
    type: 'SET_ADMIN_BRANDS',
    payload: adminBrands,
});
