import React from 'react';

const NotFound = ({ resource }) => {
  return (
    <div className='notFound'>
      No {resource} found!
    </div>
  );
}

export default NotFound;