import React, {useState, useEffect, /* useRef */} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    List,
    Datagrid,
    Pagination,
    TopToolbar,
    SimpleShowLayout,
    Show,
    ShowButton,
    EditButton,
    Edit,
    TextInput,
    SimpleForm,
    TextField,
    useDataProvider,
    Toolbar,
    useRedirect,
    useNotify,
    useRecordContext,
    useGetList,
    BooleanField,
    SearchInput,
    FunctionField,
    useShowController,
    useSaveContext,
    SaveContextProvider,
    FormDataConsumer
} from 'react-admin';
import SearchIcon from '@material-ui/icons/Search';
import {setUserDetails, setUserTotalItems} from '../actions/userActions.js';
import './styles/Custom.css';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Loader from '../components/Loader.js';
import NotFound from '../components/Profile/NotFound/NotFound.js';
import Chip from '@mui/material/Chip';
import { BRAND_DETAILS } from './Brands';

const useFetchUsers = (itemsPerPage = 30) =>{
    const page = useSelector(state => state.page.page || 1);
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const users = useSelector(state => state.user.details || []);
    const [loading, setLoading] = useState(!users || users.length === 0);

    const fetchUsers = async () => {
        try{
            setLoading(true);

            const userParams = {
                pagination:{page:page, perPage:itemsPerPage},
                sort:{field:'id', order:'ASC'},
                filter:{},
            }
            const {data, total} = await dataProvider.getList('users',userParams)
            dispatch(setUserDetails(data));
            dispatch(setUserTotalItems(total));
            setLoading(false);

        }
        catch(error){
            console.error('Error fetching users:', error);
            setLoading(false);
        }
    }

    useEffect(()=>{
        fetchUsers();
    },[page])

    return loading;
}


const UserListActions = () => (
    <TopToolbar>
    </TopToolbar>
);


const CountryNameField = ({ source }) => {
    const record = useRecordContext();
    if (!record) return <span>No countries</span>;
    if(record[source].length === 0){
        return <span>No countries</span>
    } else {
        return record[source].map((country, i) => {
        const countryId = country.split('/')[2];
        return <Chip key={i} label={countryId} sx={{margin: '4px'}}/>
    })
}
};

export const UserList = () => {
    const loadingUsers = useFetchUsers();
    const users = useSelector(state => state.user.details || []);

    const {isLoading } = useGetList(
        'users',
        { pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'ASC' } }
    );

    if (loadingUsers ||  isLoading) return <Loader/>;
    if (!users.length && !loadingUsers) {
        return <NotFound resource='users' />
    }
    const usersFilters = [
        <SearchInput key='fullName' source='fullName' alwaysOn placeholder='Name' style={{padding:'1rem 0'}}/>,
        <SearchInput key='email' source='email' alwaysOn placeholder='Email' style={{padding:'1rem 0'}}/>
    ];

    return(
        <List pagination={<Pagination rowsPerPageOptions={[]} perPage={30}/>}  actions={<UserListActions/>} filters={usersFilters}>
            <Datagrid bulkActionButtons={false} filters = {false} >
                <TextField source='sub' />
                <TextField source='email' sortable={false} />
                <FunctionField
                    label="Full name"
                    render={record => `${record.givenName} ${record.familyName}`}
                />
                <BooleanField source='enabled' sortable = {false} label='Active' />
                <CountryNameField source = 'affiliatedCountries' label = 'Affiliated Countries' sortable = {false} />
                <td className='crud_buttons'>
                    <EditButton className='edit-button'/>
                    <ShowButton className='show-button'/>
                </td>
            </Datagrid>
        </List>


    )
};

export const UserShow = props => {
    const target = useShowController()
    const dataProvider = useDataProvider();
    const user = target.record;
    const [showRoles, setShowRoles] = useState([]);
    const [showChannels, setShowChannels] = useState([]);
    const [showCountries, setShowCountries] = useState([]);
    const [isFetchingAdditionalDetails, setIsFetchingAdditionalDetails] = useState(false);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');

  
    const CountriesTitles = ({ countriesMap }) => (
        countriesMap.length > 0 ?
            <div>
                {countriesMap.map((country, index) => (
                    <div className='resource-name' key={index}>{country.name}</div>
                ))}
            </div>
        :
        <div className='empty-resource'>No countries</div>
    );

    const RolesTitles = ({ rolesMap }) => (
        rolesMap.length > 0 ?
                <div>
                    {rolesMap.map((role, index) => (
                        <div className='resource-name' key={index}>{role.name}</div>
                    ))}
                </div>
             :
             <div className='empty-resource'>No roles</div>
        );
    
    const ChannelsTitles = ({ channelsMap }) =>( 
        channelsMap.length > 0 ?
                <div>
                    {channelsMap.map((channel, index) => (
                        <div className='resource-name' key={index}>{channel.title}</div>
                    ))}
                </div>
            :
            <div className='empty-resource'>No channels</div>
        );

    const BrandsTitles = ({ source }) =>{
        const record = useRecordContext();
        if (!record) return <div className='empty-resource'>No brands</div>;
        if(record[source].length === 0){
            return <div className='empty-resource'>No brands</div>
        } else {
            const brandsLetters= record[source].map(brand => brand.split('/').pop());
            const brands = BRAND_DETAILS.filter(brand => brandsLetters.includes(brand.code))
            return  <div >{brands.map((brand, index) => <div className='resource-name' key={index}>{brand.name}</div>)}</div>
    }};

    useEffect(() => {
        if (user) {
            const fetchAdditionalDetails = async () => {
                setIsFetchingAdditionalDetails(true);
                const fetchedRoles = await Promise.all(user.avpRoles.map(userId => dataProvider.getOne('roles', { id: userId.split('/').pop().trim() })));
                setShowRoles(fetchedRoles.map(response => response.data));
                const fetchedCountries = await Promise.all(user.affiliatedCountries.map(countryId => dataProvider.getOne('countries', { id: countryId.split('/').pop().trim() })));
                setShowCountries(fetchedCountries.map(response => response.data));
                const fetchedChannels = await Promise.all(user.channels.map(channel => dataProvider.getOne('channels', {id: channel.split('/').pop(), brand: selectedBrand})));
                setShowChannels(fetchedChannels.map(response => response.data));
                setIsFetchingAdditionalDetails(false);
            };
            fetchAdditionalDetails();
        }
    }, [dataProvider]);


    if (isFetchingAdditionalDetails)  return <Loader/>;
    
    if (!user && !isFetchingAdditionalDetails) {
        return <NotFound resource='users' />
    }

    return (
      <Show {...props} title='User Show'>
        <SimpleShowLayout className='simpleShowLayout'>
            <TextField source='sub' />
            <TextField source='email' />
            <TextField source='givenName' label='First Name' />
            <TextField source='familyName' label = 'Last Name' />
            <TextField source='enabled' />
            <BrandsTitles source='brands' />
            <span className='resource-title'>Countries</span>
            <CountriesTitles countriesMap={showCountries} />
            <span className='resource-title'>Roles</span>
            <RolesTitles rolesMap={showRoles} />
            <span className='resource-title'>Channels</span>
            <ChannelsTitles channelsMap={showChannels} />
        </SimpleShowLayout>
      </Show>
    );
};

export const UserEdit = props => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify = useNotify();
    const target = useShowController()
    const dataProvider = useDataProvider();
    const user = target.record;
    const users = useSelector(state => state.user.details || []);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');

    const [searchChannelsResults, setSearchChannelsResults] = useState([]);
    // const [searchDealershipResults, setSearchDealershipResults] = useState([]);

    const [selectedChannels, setSelectedChannels] = useState([]);
    // const [selectedDealerships, setSelectedDealerships] = useState([]);

    const [searchChannelsQuery, setSearchChannelsQuery] = useState('');
    // const [searchDealershipQuery, setSearchDealershipQuery] = useState('');

    const [isSearchingChannels, setIsSearchingChannels] = useState(false);
    // const [isSearchingDealership, setIsSearchingDealership] = useState(false);

    const [isFetchingAdditionalDetails, setIsFetchingAdditionalDetails] = useState(false);


    useEffect(() => {
        if (user) {
            const fetchAdditionalDetails = async () => {
                setIsFetchingAdditionalDetails(true);
                const fetchedChannels = await Promise.all(user.channels.map(channel => dataProvider.getOne('channels', { id: channel.split('/').pop().trim(), brand: selectedBrand })));
                setSelectedChannels(fetchedChannels.map(response => response.data));
                // if (typeof user.dealerships === Array) {
                //     const fetchedDealerships = await Promise.all(user.dealerships.map(dealershipId => dataProvider.getOne('dealerships', {id: dealershipId.split('/').pop(), meta:{brand:selectedBrand}})));
                // setSelectedDealerships(fetchedDealerships.map(response => response.data));
                // }
                setIsFetchingAdditionalDetails(false);
            };
            fetchAdditionalDetails();
        }
    }, [dataProvider]);

    useEffect(() => {
        searchChannels();
    }, [searchChannelsQuery, dataProvider]);

    // useEffect(() => {
    //     searchDealership();
    // }, [searchDealershipQuery, dataProvider]);

    const searchChannels = async () => {
        if (searchChannelsQuery.length>2) {
            try {
                setIsSearchingChannels(true)
                const response = await dataProvider.getList('channels', {
                    filter: { title: searchChannelsQuery, brand: selectedBrand },
                    pagination: { page: 1, perPage: 30 },
                    sort: { field: 'title', order: 'ASC' }
                });

                setSearchChannelsResults(response.data)
            
                setIsSearchingChannels(false)

            } catch (error) {
                console.error('Error fetching Channels:', error);
                setIsSearchingChannels(false)

            }
        } else {
            setSearchChannelsResults([]);
        }
    };

    // const searchDealership = async () => {
    //     if (searchDealershipQuery.length > 2){
    //         try {
    //             setIsSearchingDealership(true)
    //             const response = await dataProvider.getList('dealerships',{
    //                 filter:{kvps: searchDealershipQuery, brand:selectedBrand},
    //                 pagination:{page:1, perPage: 30},
    //                 sort: {field: 'kvps', order: 'ASC'}
    //             });

    //             setSearchDealershipResults(response.data)
                
    //             setIsSearchingDealership(false)

    //         }catch(error){
    //             console.error('Error fetching dealerships');
    //             setIsSearchingDealership(false)
    //         }
    //     }else {
    //         setSearchDealershipResults([]);
    //     }
    // }

    const CustomToolbar = () => {
        const { save } = useSaveContext();

        const handleSaveClick = (event) => {
            event.preventDefault();
            save();
        };

        return (
            <Toolbar>
                <button style={{display:'none'}} className='save_button' onClick={handleSaveClick} type="button">
                    <SaveIcon fontSize='small'/>
                    <div className='save_text'>SAVE</div>
                </button>
            </Toolbar>
        );
    };

    const renderChannels = () =>
        selectedChannels.length === 0
        ? <div>No channels</div>
        : <div>
                {selectedChannels.map((channel, index) => (
                    <div key={index} className='country'>
                        <span className='searched_name_or_title'>
                        {channel.title}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleChannelsRemove(channel)}>
                            <CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>

    // const renderDealerships = () => {
    //     return (
    //         <div>
    //             {selectedDealerships.map((dealership, index) => (
    //                 <div key={index} className='country'>
    //                     <span className='searched_name_or_title'>
    //                         {dealership.title}
    //                     </span>
    //                     <button className='remove_button' type='button'
    //                         onClick={() => handleDealershipRemove(dealership)}>
    //                         <CloseIcon  />
    //                     </button>
    //                 </div>
    //             ))}
    //         </div>
    //     );
    // };
    
    const handleChannelsRemove = (channelToRemove) => {
        setSelectedChannels(prevChannels =>
            prevChannels.filter(channel => channel['@id'] !== channelToRemove['@id'])
        );
    };

    // const handleDealershipRemove = (dealershipToRemove) => {
    //     setSelectedDealerships(prevDealerships =>
    //         prevDealerships.filter(dealership => dealership.id !== dealershipToRemove.id)
    //     );
    // };

    const handleSearchChannelsChange = (event) => {
        const query = event.target.value
        setSearchChannelsQuery(query);
        if(query.length <=2 ){
            setSearchChannelsResults([]);
        }
    };
    
    // const handleSearchDealershipChange = (event) => {
    //     const query = event.target.value
    //     setSearchDealershipQuery(query);
    //     if(query.length <=2 ){
    //         setSearchDealershipResults([]);
    //     }
    // };

    const handleChannelsSelect = (channel) => {
        setSelectedChannels(prev => {
            const isDuplicate = prev.some(existingChannel => existingChannel.id === channel.id);
            return isDuplicate ? prev : [...prev, channel];
        });
        setSearchChannelsResults([]);
    };

    // const handleDealershipSelect = (dealership) => {
    //     setSelectedDealerships(prev => {
    //         const isDuplicate = prev.some(existingDealership => existingDealership.id === dealership.id);
    //         return isDuplicate ? prev : [...prev, dealership];
    //     });
    //     setSearchDealershipResults([]);
    // };


    const customSave = () => {};
    if (isFetchingAdditionalDetails) return <Loader/>

    return (
        <Edit {...props} title={user.email}>
            <SaveContextProvider value = {{save: customSave}} >
                <SimpleForm toolbar = {<CustomToolbar/>}>
                    <TextInput fullWidth source='email' disabled/>
                    <TextInput fullWidth source='givenName'/>
                    <TextInput fullWidth source='familyName'/>
                    <h3 className='countries_title'>Channels:</h3>
                    <div className='countries' style={{marginBottom:'20px'}}>
                        {renderChannels()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search for Channels'
                                value = {searchChannelsQuery}
                                onChange={handleSearchChannelsChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>

                        {searchChannelsQuery.length > 2 && searchChannelsResults.length > 0 && !isSearchingChannels && searchChannelsResults.map(channel => (
                            <div className='search-result-options' key={channel.id} onClick={() => handleChannelsSelect(channel)}>
                                {channel.title}
                            </div>
                        ))}
                        {searchChannelsQuery.length > 2 && searchChannelsResults.length == 0  && !isSearchingChannels &&
                            <div className = 'no-result'>
                                no results
                            </div>
                        }
                        {isSearchingChannels && <div>Searching...</div>}
                    </div>
                    {/* <h3 className='countries_title'>Dealerships:</h3>
                    <div className='countries' style={{marginBottom:'20px'}}>
                        {renderDealerships()}
                        <div className='search_container'>
                            <input
                                className='countries_searchbar'
                                type='text'
                                placeholder='Search for dealership by KVPS'
                                value = {searchDealershipQuery}
                                onChange={handleSearchDealershipChange}
                            />
                            <SearchIcon className='search_icon'/>
                        </div>

                        {searchDealershipResults.length > 0 && !isSearchingDealership && searchDealershipResults.map(dealership => (
                            <div className='search-result-options' key={dealership.id} onClick={() => handleDealershipSelect(dealership)}>
                                {dealership.title}
                            </div>
                        ))}
                        {searchDealershipQuery.length > 2 && searchDealershipResults.length == 0  && !isSearchingDealership &&
                            <div className = 'no-result'>
                                no results
                            </div>
                        }
                        {isSearchingDealership && <div>Searching...</div>}
                    </div> */}
                    <FormDataConsumer>
                        {({ formData, }) => {
                            const handleCustomSave = async () => {
                                let dataToSet= {};
                                const previousData = JSON.parse(JSON.stringify(formData));
                                dataToSet.givenName = formData.givenName
                                dataToSet.familyName = formData.familyName
                                dataToSet.channels = selectedChannels.map(user=>user['@id'])

                                try {
                                    await dataProvider.update('users', { id:user.sub, data: dataToSet, previousData: previousData})

                                    const updatedUsers = users.map((user) => {
                                        if(user.sub === previousData.sub){
                                            return {...user, ...formData};
                                        }
                                        return user;
                                    })

                                    dispatch(setUserDetails(updatedUsers));

                                    notify('Dealership user updated successfully', 'info');
                                    redirect(`/users/${user.sub}/show`);
                                } catch (error) {
                                    console.error('Error updating dealership group:', error);
                                    notify(`Error updating dealership group: ${error.message}`, 'warning');
                                }
                            };

                            return (
                                <button style={{position:'relative', top:'70px', zIndex:'99'}} className='save_button' onClick={e => handleCustomSave(e)} type="button">
                                    <SaveIcon fontSize='small'/>
                                    <div className='save_text'>SAVE</div>
                                </button>
                            );
                        }}
                    </FormDataConsumer>

                </SimpleForm>
            </SaveContextProvider>

        </Edit>
   )
}