import { SET_FAQ_DATA, SET_FAQ_DETAILS, SET_FAQ_CHANNELS } from '../actions/faqActions.js';

const initialState = {
    data:null,
    details:null,
    channels:[],
};

const faqReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_FAQ_DATA:
            return {
                ...state,
                data: action.payload
            };
        case SET_FAQ_DETAILS:
            return{
                ...state,
                details:action.payload
            }
        case SET_FAQ_CHANNELS:
            return {
                ...state,
                channels:action.payload
            }
        default:
            return state;
    }
};

export default faqReducer;