import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ProfileButton = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/profile');
    };

    return (
        <MenuItem onClick={handleNavigate}>
            <AccountCircleIcon /> Profile
        </MenuItem>
    );
};

export default ProfileButton;