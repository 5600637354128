import React from 'react';

const ModuleTypesIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            dashboard-small
        </title>
        <path d="M18.5,18.5 L23.5,18.5 L23.5,23.5 L18.5,23.5 L18.5,18.5 Z M9.5,18.5 L14.5,18.5 L14.5,23.5 L9.5,23.5 L9.5,18.5 Z M0.5,18.5 L5.5,18.5 L5.5,23.5 L0.5,23.5 L0.5,18.5 Z M18.5,9.5 L23.5,9.5 L23.5,14.5 L18.5,14.5 L18.5,9.5 Z M9.5,9.5 L14.5,9.5 L14.5,14.5 L9.5,14.5 L9.5,9.5 Z M0.5,9.5 L5.5,9.5 L5.5,14.5 L0.5,14.5 L0.5,9.5 Z M18.5,0.5 L23.5,0.5 L23.5,5.5 L18.5,5.5 L18.5,0.5 Z M9.5,0.5 L14.5,0.5 L14.5,5.5 L9.5,5.5 L9.5,0.5 Z M0.5,0.5 L5.5,0.5 L5.5,5.5 L0.5,5.5 L0.5,0.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
);
export default ModuleTypesIcon;
