export const SET_DEALERSHIP_DATA = 'SET_DEALERSHIP_DATA';
export const setDealershipData = (data) => ({
    type: SET_DEALERSHIP_DATA,
    payload: data,
});

export const SET_DEALERSHIP_DETAILS = 'SET_DEALERSHIP_DETAILS';
export const setDealershipDetails = (details) => ({
    type: SET_DEALERSHIP_DETAILS,
    payload: details,
});

export const SET_DEALERSHIP_DEALERSHIP_GROUPS = 'SET_DEALERSHIP_DEALERSHIP_GROUPS';
export const setDealershipDealershipGroups = (dealershipGroups) => ({
    type: SET_DEALERSHIP_DEALERSHIP_GROUPS,
    payload: dealershipGroups,
});

export const SET_DEALERSHIP_COUNTRY_NAMES = 'SET_DEALERSHIP_COUNTRY_NAMES';
export const setDealershipCountryNames = (countryNames) => ({
    type: SET_DEALERSHIP_COUNTRY_NAMES,
    payload: countryNames,
});

export const SET_DEALERSHIP_TOTAL_ITEMS = 'SET_DEALERSHIP_TOTAL_ITEMS';
export const setDealershipTotalItems = (totalItems) => ({
    type: SET_DEALERSHIP_TOTAL_ITEMS,
    payload: totalItems,
});

export const SET_DEALERSHIP_SHOW_DETAILS = 'SET_DEALERSHIP_SHOW_DETAILS';
export const setDealershipShowDetails = (showDetails) => ({
    type: SET_DEALERSHIP_SHOW_DETAILS,
    payload: showDetails,
});