import {
    useDataProvider,
    TextField,
    NumberField,
    TextInput,
    Edit,
    Create,
    SimpleForm,
    NumberInput,
    Show,
    SimpleShowLayout,
    EditButton,
    ShowButton,
    CreateButton,
    TopToolbar,
    SelectInput,
    FunctionField,
    Toolbar,
    SaveButton,
    useNotify,
    useRedirect,
    Title,
    required,
    Confirm,
    useRecordContext,
    Button
} from 'react-admin';
import React, { useState, useEffect } from 'react';
import { CustomImageField } from '../fields/CustomImageField';
import { useParams } from 'react-router-dom';
import { setCarTypeDetails, setCarTypeTotalItems } from '../actions/carTypeActions';
import {useDispatch, useSelector} from 'react-redux';
import { BRAND_DETAILS } from './Brands';
import Loader from '../components/Loader';
import NotFound from '../components/Profile/NotFound/NotFound';
import { setPage } from '../actions/pageActions';
import Pagination from '../components/Pagination';
import {useFetchCarModels } from './CarModels';
import DeleteIcon from '../assets/icons/DeleteIcon';

export const useFetchCarTypes = () => {
    useFetchCarModels(1);
    const page = useSelector(state => state.page.page || 1);
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const carTypes = useSelector((state) => state.carType.details || []);
    const [loading, setLoading] = useState(!carTypes || carTypes.length === 0);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');

    const fetchCarTypes = async () => {
        setLoading(true);
        try {
            const carTypesParams = {
                pagination: { page: page }
            };

            const { data: carTypes, total } = await dataProvider.getList('carTypes', carTypesParams);

            dispatch(setCarTypeDetails(carTypes));
            if (total) dispatch(setCarTypeTotalItems(total));
        } catch (error) {
            console.error('Error fetching car types:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCarTypes();
    }, [page, selectedBrand, dispatch]);

    useEffect(() => {
        if (!carTypes || carTypes.length === 0) {
            fetchCarTypes();
        } else {
            setLoading(false);
        }
    }, []);
    return loading;
};

const CarTypeListActions = () => (
    <TopToolbar>
        <CreateButton label='Create' />
    </TopToolbar>
);

export const CarTypeList = () => {
    const dispatch = useDispatch();

    const  loading = useFetchCarTypes();
    const totalItems = useSelector(state=>state.carType.totalItems || 0);
    const page = useSelector(state => state.page.page);
    const selectedBrand = useSelector(state => state.brand.brand);


    const carTypes = useSelector(state=>state.carType.details || []);

    const handlePageChange = (newPage) => {
        dispatch(setPage(newPage))
    };

    useEffect(() => {
        if(selectedBrand){
            dispatch(setPage(1));
        }
    }, [selectedBrand, dispatch]);

    if (loading) return <Loader/>;
    if (!carTypes || carTypes.length ===0) {
        return (<>
                <CarTypeListActions/>
                <NotFound resource = 'car types'/>
                </>
        )
    }
    return (
        <div className='customDataGrid_carModels'>
            <Title title='Car Types'/>
            <CarTypeListActions/>
            <table className='carModels_table'>
                <thead>
                    <tr className='carModelHead'>
                        <th>Brand</th>
                        <th>Type</th>
                        <th>Image</th>
                        <th>Position</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {carTypes.map(carType => {
                        const brandCode = carType.brand.split('/').pop();
                        const brand = BRAND_DETAILS.find(b => b.code === brandCode);
                        const brandName = brand ? brand.name : carType.brand;

                        return (
                            <tr key={carType.id} className='carModelRow'>
                                <td>{brandName}</td>
                                <td>{carType.type}</td>
                                <td>
                                    <img className='carModelImage' src={carType.imageUrl} alt={carType.type} />
                                </td>
                                <td>{carType.position}</td>
                                <td className='crud_buttons'>
                                    <EditButton className='edit-button' basepath='/carTypes' record={carType} />
                                    <ShowButton className='show-button' basepath='/carTypes' record={carType} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Pagination total={totalItems} current={page} onChange={handlePageChange} />
        </div>
    );
};

const CustomDeleteButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleDelete = async () => {
        setOpen(false);
        try {
            await dataProvider.delete('carTypes', { id: record['@id'] });
            notify('Type deleted successfully', 'info');
            redirect('/carTypes');
        } catch (error) {
            notify(`Error deleting Type: ${error.message}`, 'warning');
        }
    };
    return (
        <>
            <Button label="Delete" onClick={() => setOpen(true)} style = {{marginLeft:'10px',color:'red'}}>
                <DeleteIcon />
            </Button>

            <Confirm
                isOpen={open}
                loading={false}
                title="Are you sure you want to delete this type?"
                content="This action is irreversible."
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const CustomToolbar = props => {
    const isAdmin = localStorage.getItem('isAdmin');

    return (
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'space-between'}}>
            <SaveButton />
            {isAdmin === 'true' && <CustomDeleteButton />}
        </Toolbar>
)};

export const CarTypeEdit = (props) => {
    const dispatch = useDispatch();
    const loading = useFetchCarTypes();
    const {id} = useParams();
    const carTypes = useSelector(state => state.carType.details);
    const carType = carTypes ? carTypes.find(type => type.uuid === id) : null;
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify=useNotify();

    if (loading){
        return <Loader/>
    }
    if (!carType) {
        return <NotFound resource='car types'/>
    }

    const onSuccess = async (data) => {
        try {
            let dataToSet = {};
            dataToSet.type = data.type;
            dataToSet.imageUrl = data.imageUrl;
            dataToSet.position = data.position;
            dataToSet.model = data.model;

            await dataProvider.update('carTypes', { id: data['@id'], data: dataToSet });
            const updatedCarTypes = carTypes.map((type) => {
                if (type.id === data.id) {
                  return { ...type, ...data };
                }
                return type;
            });
            dispatch(setCarTypeDetails(updatedCarTypes));
            notify('Car type updated successfully', 'info');
            redirect(`/carTypes/${data.id}/show`);
        } catch (error) {
            console.error('Error updating car type:', error);
            notify(`Error updating car type: ${error.message}`, 'warning');
        }
    };
    const onError= (error) => {
        console.error('Error updating car type:', error);
        notify(`Error updating ${error.message}`,'warning')
    }
    
    const carModels = useSelector((state) => state.carModel.details || []);

    const carModelChoices = carModels.map(carModel => {
        const brandCode = carModel.brand.split('/').pop();
        return {
            id: `/brands/${brandCode}/car-models/${carModel.uuid}`,
            name: carModel.model
        };
    });
    return(
        <Edit {...props} mutationOptions={{onSuccess, onError}} redirect='show' title='Car Type Edit' queryOptions={{ meta: { id: carType['@id']}}}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput source='type' fullWidth validate={[required()]}  />
            <TextInput source='imageUrl' fullWidth multiline validate={[required()]} />
            <NumberInput source='position' fullWidth validate={[required()]} />
            <SelectInput
                    source='model'
                    label='Car Model'
                    choices={carModelChoices}
                    optionText='name'
                    optionValue='id'
                    fullWidth
                    validate={[required()]}
                />
        </SimpleForm>
        </Edit>
    )
};

const extractCarTypeData = (data) => {
    return {
        '@id':data['@id'],
        '@type':data['@type'],
        brand: data.brand,
        id: data.uuid,
        imageUrl: data.imageUrl,
        model: data.model,
        modelName:data.modelName,
        position: data.position,
        type:data.type,
        uuid:data.uuid,
    };
};

export const CarTypeCreate = (props) => {
    useFetchCarTypes();
    const dispatch = useDispatch();
    const notify = useNotify();
    const redirect = useRedirect();
    const carModels = useSelector((state) => state.carModel.details || []);
    const carTypes = useSelector((state) => state.carType.details || []);
    const selectedBrand = useSelector(state => state.brand.brand);

    const carModelChoices = carModels.map(carModel => {
        const brandCode = carModel.brand.split('/').pop();
        return {
            id: `/brands/${brandCode}/car-models/${carModel.uuid}`,
            name: carModel.model
        };
    });

    const onSuccess = async (data) => {
        try {
            const extractedData = extractCarTypeData(data);
            notify('Car type created successfully', 'info');
            redirect(`/carTypes/${data.id}/show`);
            const updatedCarTypes = [extractedData, ...carTypes]
            dispatch(setCarTypeDetails(updatedCarTypes));
        } catch (error) {
            console.error('Error creating car type:', error);
            notify(`Error creating car type: ${error.message}`, 'warning');
        }
    };

    const onError= (error) => {
        console.error('Error creating car type:', error);
        notify(`Error creating ${error.message}`,'warning')
    }

    return (
        <Create {...props} mutationOptions = {{onSuccess, onError}}>
            <SimpleForm defaultValues={()=>({brand: '/brands/'+selectedBrand })} >
                <TextInput source='type' label='Type (example: limousine)' fullWidth validate={[required()]} />
                <TextInput source='imageUrl' label='Image URL (must not be empty)' fullWidth multiline validate={[required()]} />
                <NumberInput source='position' fullWidth validate={[required()]} />
                <SelectInput
                    source='model'
                    label='Car Model'
                    choices={carModelChoices}
                    optionText='name'
                    optionValue='id'
                    fullWidth
                />
            </SimpleForm>
        </Create>
    );
};

export const CarTypeShow = (props) => {
    const {id}  = useParams();
    const code = id.split('/').pop().trim();
    const dataProvider = useDataProvider();
    const [model, setModel] = useState('');
    const carTypes = useSelector(state => state.carType.details);
    const carType = carTypes ? carTypes.find(type => type.uuid === code) : null;
    const carModelUuid = carType.model.split('/').pop().trim();

    const fetchModel = async()=> {
        const {data} = await dataProvider.getOne('carModels', { id: carModelUuid });
        setModel(data.model)
    } 

    useEffect(() => {
        fetchModel();
    }, []);

    if (!carType) {
        return <NotFound resource = 'car types'/>
    }

    return (
        <Show {...props} title='Car Type Show' queryOptions={{ meta: { id: carType['@id']}}}>
            <SimpleShowLayout >
                <FunctionField label='Brand' render={(record) => {
                        const brandCode = record.brand.split('/').pop().trim();
                        return BRAND_DETAILS.find(brand => brand.code === brandCode)?.name || '';
                }} />
                <TextField source='type' label='Type' />
                <CustomImageField source='imageUrl' label='Image' />
                <NumberField source='position' label='Position' />
                <FunctionField label='Model' render={()=> <p>{model}</p>} />
            </SimpleShowLayout>
        </Show>
    );
};