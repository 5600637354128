import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ConnectView from './ConnectView';
import ApiAdmin from './ApiAdmin';

const App = () => (

    <BrowserRouter>
        <Routes>
            <Route path="/connect" element={<ConnectView />} />
            <Route path="/*" element={<ApiAdmin />} />
        </Routes>
    </BrowserRouter>

);

export default App;