import React, { useState } from 'react';
import { useRecordContext, useDataProvider } from 'react-admin';
import '../pages/styles/Custom.css';

export const SplashScreenField = ({ source }) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [editMode, setEditMode] = useState(false);
    const [urlInput, setUrlInput] = useState(record[source]);

    const handleUrlChange = (e) => {
        setUrlInput(e.target.value);
    };

    const handleSave = async () => {
        try {
            await dataProvider.update('brands', {
                id: record.id,
                data: { ...record, [source]: urlInput },
                previousData: record,
            });
            setEditMode(false); // Exit edit mode after successful update
        } catch (error) {
            console.error('Error updating splash screen URL:', error);
        }
    };

    return (
        <div className='align-center'>
            {!editMode ? (
                <img
                    src={record[source]}
                    alt='Splash Screen'
                    className='style-image'
                    title='Click to change the splash screen URL'
                    onClick={() => setEditMode(true)}
                />
            ) : (
                <div>
                    <input
                        type='text'
                        value={urlInput}
                        onChange={handleUrlChange}
                        style={{ width: '100%' }}
                    />
                    <button onClick={handleSave}>Save</button>
                    <button onClick={() => setEditMode(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
};

SplashScreenField.defaultProps = { addLabel: true };
