import React, { useEffect, useState, useCallback} from 'react';
import {
    useDataProvider,
    TopToolbar,
    SimpleShowLayout,
    Show,
    TextField,
    ShowButton,
    useRecordContext,
    TextInput,
    Edit,
    SimpleForm,
    useNotify,
    useRedirect,
    Button,
    Title,
    EditButton,
    useRefresh,
    useShowController
} from 'react-admin';
import {useDispatch, useSelector} from 'react-redux';
import {setDealershipDetails, setDealershipTotalItems } from '../actions/dealershipActions.js';
import {BRAND_DETAILS} from './Brands.js';
import Loader from '../components/Loader.js';
import NotFound from '../components/Profile/NotFound/NotFound.js';
import {setPage} from '../actions/pageActions';
import Pagination from '../components/Pagination';
import { debounce } from 'lodash';

const useFetchDealerships = (itemsPerPage=30) => {
    const page = useSelector(state => state.page.page || 1);
    const selectedBrand = useSelector((state)=> state.brand.brand || 'A');
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const dealerships = useSelector(state => state.dealership.details || []);
    const [loading, setLoading] = useState(!dealerships || dealerships.length === 0);

    const fetchDealerships = async () => {
        setLoading(true);
        try {
            const dealershipParams = {
                pagination: { page: page, perPage: itemsPerPage },
                sort: { field: 'id', order: 'ASC' },
                filter: { brand: selectedBrand },
            };
            const { data, total } = await dataProvider.getList('dealerships', dealershipParams);

            dispatch(setDealershipTotalItems(total));
            dispatch(setDealershipDetails(data));
        } catch (error) {
            console.error('Error fetching dealerships:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        if(!dealerships || dealerships.length===0){
            fetchDealerships();
        } else {
            setLoading(false);
        }
    },[])

    useEffect(()=>{
        fetchDealerships();
    },[page, selectedBrand])

    return loading;
}

const DealershipListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

export const DealershipsList = () =>{
    const dispatch = useDispatch();
    const loadingDealerships = useFetchDealerships();
    const dealerships = useSelector(state => state.dealership.details || [] );
    const totalItems = useSelector(state=>state.dealership.totalItems || 0);
    const selectedBrand = useSelector(state => state.brand.brand);
    const page = useSelector(state => state.page.page);
    const dataProvider = useDataProvider();

    const [searchDealershipQuery, setSearchDealershipQuery] = useState('');
    const [searchDealershipResults, setSearchDealershipResults] = useState([]);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        searchDealership('');
    }, [dataProvider]);


    const debouncedSearchDealership = useCallback(
        debounce((query) => {
            searchDealership(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDealership.cancel();
        };
    }, [debouncedSearchDealership]);

    const searchDealership = async (query) => {
        if(query.length > 2){
            try {
                setIsLoading(true)
                const response = await dataProvider.getList('dealerships',{
                    filter:{kvps: query},
                    pagination:{page:1},
                });
                setSearchDealershipResults(response.data);
                setShowSearchResults(true);
                setIsLoading(false)
            }catch(error){
                console.error('Error fetching dealerships', dealerships);
                setIsLoading(false)
            }
        }else {
            setShowSearchResults(false);
            setSearchDealershipResults([]);
        }
    };

    const handleSearchDealershipChange = (event) => {
        const query = event.target.value
        setSearchDealershipQuery(query);
        debouncedSearchDealership(query);
    };

    const handlePageChange = (newPage) => {
        dispatch(setPage(newPage))
    };

    useEffect(() => {
        if(selectedBrand){
            dispatch(setPage(1));
        }
    }, [selectedBrand, dispatch]);

    if (loadingDealerships) return <Loader/>;
    if(!dealerships.length && !loadingDealerships) return <NotFound resource='dealerships' />

    const dealershipsToDisplay = showSearchResults ? searchDealershipResults : dealerships;

    return (
        <div className='customGrid_dealership'>
            <div className='search_container'>
                <input
                    className='countries_searchbar'
                    type='text'
                    placeholder='Search for dealership by KVPS'
                    value = {searchDealershipQuery}
                    onChange={handleSearchDealershipChange}
                />
            </div>

            <Title title = 'Dealerships'/>
            <DealershipListActions/>
            {isLoading ? 
            <Loader/> :
            <table className='dealerships_table'>
                <thead>
                    <tr className='dealershipsHead'>
                        <th>KVPS</th>
                        <th>Title</th>
                        <th>Address</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {dealershipsToDisplay.map(dealership => {
                        return (
                            <tr key = {dealership.id} className='dealershipsRow'>
                                <td>{dealership.kvps}</td>
                                <td>{dealership.title}</td>
                                <td>{dealership.address.street+ ', '+ dealership.address.zipCode}</td>
                                <td className='crud_buttons'>
                                    <EditButton basepath='/dealerships' record={dealership} />
                                    <ShowButton basePath={`/dealerships/${dealership.id}/show`} record={dealership} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>}

            {!showSearchResults && <Pagination total={totalItems} current={page} onChange={handlePageChange} />}
        </div>
    );
}

const BrandNameField = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    const brandCode = record[source].split('/').pop();
    const brand = BRAND_DETAILS.find(b => b.code === brandCode);
    return <span>{brand ? brand.name : 'Unknown'}</span>;
};

const AddressField = ({ source }) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;

    return (
        <div>
            {Object.entries(record[source])
                .filter(([key]) => key !== '@type' && key !== '@id')
                .map(([key, value], index) => (
                    <div key={index}>
                        {key}: {value}
                    </div>
                ))}
        </div>
    );
};


export const DealershipsShow = () => {
    const { isLoading, record } = useShowController();
    if (isLoading) return <Loader />;
    return (
        <Show title = {record.id}>
            <SimpleShowLayout>
                <TextField source='title'/>
                <TextField source='kvps'/>
                <BrandNameField source='brand' label = 'Brand' />
                <AddressField source='address' label='Address'/>
            </SimpleShowLayout>
        </Show>
    )
};

export const DealershipsEdit = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const onSuccess = async (data) => {
        try {
            await dataProvider.update('dealerships', {
                id: data['@id'],
                data: data,
                previousData: data
            });
            notify('dealership data updated successfully', 'info');
            redirect(`/dealerships/${data.id}/show`);
            refresh();
        } catch (error) {
            console.error('Error updating dealership', error);
            notify(`Error updating ${error.message}`, 'warning');
        }
    };
    return (
        <Edit {...props} title = 'Dealership Edit' mutationOptions={{ onSuccess }} >
                <SimpleForm>
                    <TextInput fullWidth source='title' required/>
                    <TextInput fullWidth source='address.street' label='Street' required/>
                    <TextInput fullWidth source='address.city' label='City' required/>
                    <TextInput fullWidth source='address.zipCode' label='Zip Code' required/>
                    <TextInput fullWidth source='address.state' label='State' required/>
                    <TextInput fullWidth source='owner'/>
                </SimpleForm>
        </Edit>
    )
}

export const DealershipsDelete = ({ record }) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const dealerships = useSelector(state => state.dealership.details || []);

    const handleDelete = async () => {
        try {
            await dataProvider.delete('dealerships', { id: record.id });
            const updatedDealerships = dealerships.filter(dealership => dealership.id !== record.id);
            dispatch(setDealershipDetails(updatedDealerships))
            notify('Dealership deleted successfully', 'info');
            redirect('/dealerships');
        } catch (error) {
            notify(`Error deleting dealership: ${error.message}`, 'warning');
            console.error('Error deleting dealership:', error);
        }
    };
    return <Button onClick={handleDelete}>Delete</Button>;
};