import React from 'react';

const Checkmark = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>
            select-small
        </title>
        <path d="M6 12.2L10.3 16.4 18.4 6.1" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
)
export default Checkmark;