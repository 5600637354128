import CarModelDataTransformer from './dataTransformers/CarModelDataTransformer';
import CarTypesDataTransformer from './dataTransformers/CarTypesDataTransformer';
import CountryDataTransformer from './dataTransformers/CountryDataTransformer';
import ModuleTypeDataTransformer from './dataTransformers/ModuleTypeDataTransformer';
import UserDataTransformer from './dataTransformers/UserDataTransformer';
import ChannelDataTransformer from './dataTransformers/ChannelDataTransformer';
import DealershipDataTransformer from './dataTransformers/DealershipDataTransformer';
import DealershipGroupDataTransformer from './dataTransformers/DealershipGroupDataTransformer';
import DeviceDataTransformer from './dataTransformers/DeviceDataTransformer';
import FaqDataTransformer from './dataTransformers/FaqDataTransformer';
import AssetOveridesDataTransformer from './dataTransformers/AssetOverridesDataTransformer';
import RoleDataTransformer from './dataTransformers/RoleDataTransformer';

class ApiDataTransfomer {
    transformerCollection = []
    addTransformer(transformer) {
        this.transformerCollection.push(transformer);
    }

    transformCollection(data, resource, params, query) {
        return this.findTransformer('transformCollection', data, resource, params, query)
            ?? data['hydra:member'].map(resource => ({ ...resource, id: resource['@id'] }));
    }

    transformSingle(data, resource, params, query) {
       if(data){
        return this.findTransformer('transformSingle', data, resource, params, query)
            ?? { ...data, id: data['@id'] }
        } else null
    }

    transformCollectionBeforeUpdate(data, resource, params, query) {
        return this.findTransformer('transformCollectionBeforeUpdate', data, resource, params, query) ?? data['hydra:member'].map(resource => ({ ...resource, id: resource['@id'] }));
    }

    transformSingleBeforeUpdate(data, resource, params, query) {
        return this.findTransformer('transformSingleBeforeUpdate', data, resource, params, query) ?? { ...data};
    }

    findTransformer(method, data, resource, params, query) {
        for(var i = 0; i < this.transformerCollection.length; i++) {
            let transformer = this.transformerCollection[i];

            if (transformer.supports(resource, params, query) && typeof transformer[method] === 'function') {
                return transformer[method](data, resource, params, query);
            }
        }

        return null;
    }
}

let dataTransformer = new ApiDataTransfomer();
dataTransformer.addTransformer(new CountryDataTransformer());
dataTransformer.addTransformer(new CarModelDataTransformer());
dataTransformer.addTransformer(new CarTypesDataTransformer());
dataTransformer.addTransformer(new ModuleTypeDataTransformer());
dataTransformer.addTransformer(new UserDataTransformer());
dataTransformer.addTransformer(new ChannelDataTransformer());
dataTransformer.addTransformer(new DealershipDataTransformer());
dataTransformer.addTransformer(new DealershipGroupDataTransformer());
dataTransformer.addTransformer(new DeviceDataTransformer());
dataTransformer.addTransformer(new FaqDataTransformer());
dataTransformer.addTransformer(new AssetOveridesDataTransformer());
dataTransformer.addTransformer(new RoleDataTransformer());
export default dataTransformer;
