import {store} from '../../store';

class FaqEndpointResolver {
    supports(resource) {
        return resource === 'faqs';
    }


    constructUriFromRedux(params){
        if (!params.id) {
            console.error('Faq ID is missing.');
            throw new Error('Faq ID is missing.');
        }

        if (params.filter && params.filter.brand && params.slug) {
            const brandCode = params.filter.brand;
            return `/brands/${brandCode}/channels/${params.slug}/faqs/${params.id}`;
        }

        const id = params.id;
        const state = store.getState();
        if (!state || !state.faq || !state.faq.details) {
            console.error('Store state, Faq, or Faq details are undefined.');
            throw new Error('Store state, Faq, or Faq details are undefined.');
        }
        const faqs = state.faq.details;
        const faq = faqs.find(faq => faq.id === id);

        if (!faq) {
            console.error(`No faq found with ID: ${id}`);
            return '';
        }
        const brandCode = faq.brand.split('/').pop();
        const channelSlug = faq.channel.split('/').pop();
        if (!brandCode) {
            console.error('Brand code is missing or invalid.');
            return '';
        }
        return `/brands/${brandCode}/channels/${channelSlug}/faqs/${params.id}`;
    }


    getOneUri = (_resource, params) => {
        console.log(params)
        return this.constructUriFromRedux(params)
    }

    getListUri = (_resource, params) => {
        const state = store.getState();
        const channelSlug = params.filter.slug
        return `/brands/${state.brand.brand}/channels/${channelSlug}/faqs`;
    };

    getUpdateUri = (_resourcae, params) => {
        const state = store.getState();
        const channelSlug=params.data.channel.split('/').pop();
        const uuid = params.data.id.split('/').pop();
        return `/brands/${state.brand.brand}/channels/${channelSlug}/faqs/${uuid}`;

    }

    getCreateUri = (_resource, params) => {
        const state = store.getState();
        const channelSlug = params.data.channel.split('/').pop();
        return `/brands/${state.brand.brand}/channels/${channelSlug}/faqs`;
    }

    getDeleteUri = (_resource, params) => {
        return params.id;
    }
}

export default FaqEndpointResolver;
