import queryString from 'query-string';
// import {store} from '../../store';

class UserEndpointResolver {
    supports(resource) {
        return resource === 'users';
    }
    getOneUri(_resource, params) {
        return `/users/${params.id}`
    }
    getUpdateUri(_resource, params){
        return `/users/${params.id}`
    }
    getListUri(_resource, params){
        return `/users?${queryString.stringify(params.pagination)}&${queryString.stringify(params.filter)}`;
    }
}

export default UserEndpointResolver;