import React, { useEffect, useState } from 'react';
import {
    CreateButton,
    useDataProvider,
    TopToolbar,
    TextInput,
    SimpleForm,
    Edit,
    useRedirect,
    SaveButton,
    Toolbar,
    EditButton,
    ShowButton,
    Show,
    TextField,
    BooleanInput,
    BooleanField,
    SimpleShowLayout,
    useRecordContext,
    Create,
    useNotify,
    useRefresh,
    Title,
} from 'react-admin';
import {useParams} from 'react-router-dom';
import { BRAND_DETAILS } from './Brands';
import './styles/Custom.css';
import { setChannelDetails } from '../actions/channelActions';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../components/Loader';
import NotFound from '../components/Profile/NotFound/NotFound';

const ChannelListActions = () => (
  <TopToolbar>
    <CreateButton label='Create' />
  </TopToolbar>
);

const CustomToolbar = props => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
);

const useFetchChannels = () =>{
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const channels = useSelector(state => state.channel.details || []);
    const [loading, setLoading] = useState(!channels || channels.length ===0)
    const selectedBrand = useSelector(state => state.brand.brand);

    const fetchChannels = async () => {
        try {
            setLoading(true);

            const channelParams = {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'ASC' },
                filter: { brand: selectedBrand },
            }
            const {data} = await dataProvider.getList('channels', channelParams);
            
            dispatch(setChannelDetails(data));
            setLoading(false);
        }
        catch(error){
            console.error('Error fetching channels:', error);
            setLoading(false);
        }
    }

    useEffect(()=>{
        fetchChannels();
    },[selectedBrand])

    return loading;
}

export const ChannelList = () => {
    const loading = useFetchChannels();
    const channels = useSelector(state => state.channel.details || []);

    if (loading) return <Loader/>;
    if(!channels || channels.length ===0) return <NotFound resource='channels'/>

    return (
        <div className='customDataGrid_moduleTypes'>
            <Title title = 'Channels' />
            <ChannelListActions/>
            <table className='channels_table'>
                <thead>
                    <tr className='carModelHead'>
                        <th>Brand</th>
                        <th>Title</th>
                        <th>Slug</th>
                        <th>Country aware</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {channels.map(channel =>{
                        const brandCode = channel.brand.split('/').pop();
                        const brand = BRAND_DETAILS.find(b=>b.code === brandCode);
                        const brandName=brand ? brand.name :channel.brand;
                        return(
                            <tr key = {channel.id}  className='channelRow'>
                                <td>{brandName}</td>
                                <td>{channel.title}</td>
                                <td>{channel.slug}</td>
                                <td>{String(channel.countryAware)}</td>
                                <td className='crud_buttons'>
                                    <EditButton className='edit-button'  basepath='/channels' record={channel} />
                                    <ShowButton  className='show-button' basepath='/channels' record={channel} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

export const ChannelEdit = props => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();
    const loading = useFetchChannels();
    const { id } = useParams();
    const channels = useSelector(state => state.channel.details);
    const channel = channels ? channels.find(channel => channel.slug === id) : null;
    const selectedBrand = useSelector(state => state.brand.brand);

    if (loading){
        return <Loader/>
    }
    if (!channel) {
        return <NotFound resource='channels'/>
    }

    const onSuccess = async (data) => {
      try {
          await dataProvider.update('channels', { id: data.id, data });
          const updatedChannels = channels.map((channel) => {
              if (channel.id === data.id) {
                return { ...channel, ...data };
              }
              return channel;
          });
          dispatch(setChannelDetails(updatedChannels));
          notify('Channel updated successfully', 'info');
          redirect(`/channels/${data.id}/show`);
      } catch (error) {
          console.error('Error updating channel:', error);
          notify(`Error updating channel: ${error.message}`, 'warning');
      }
    };
    const onError= (error) => {
      console.error('Error updating channel:', error);
      notify(`Error updating ${error.message}`,'warning')
    }

    return (
      <Edit {...props} mutationOptions = {{onSuccess, onError}} title='Channel Edit' queryOptions={{ meta: { brand: selectedBrand}}} >
        <SimpleForm toolbar={<CustomToolbar/>} >
          <TextInput fullWidth source='title' />
          <BooleanInput source='countryAware' />
        </SimpleForm>
      </Edit>
    );
};

const BrandNameField = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    const brandCode = record[source].split('/').pop();
    const brand = BRAND_DETAILS.find(b => b.code === brandCode);
    return <span>{brand ? brand.name : 'Unknown'}</span>;
};

export const ChannelShow = props =>{
    const loading = useFetchChannels();
    const { id } = useParams();
    const channels = useSelector(state => state.channel.details);
    const channel = channels ? channels.find(channel => channel.slug === id) : null;
    const selectedBrand = useSelector(state => state.brand.brand);

    if (loading){
        return <Loader/>
    }
    if (!channel) {
        return <NotFound resource='channels' />
    }
        return (
            <Show {...props} title = 'Channel Show' queryOptions={{ meta: { brand: selectedBrand}}}>
                <SimpleShowLayout>
                    <TextField source = 'title' />
                    <BrandNameField source='brand'/>
                    <TextField source = 'slug' />
                    <BooleanField source = 'countryAware' />
                </SimpleShowLayout>
            </Show>
        )
}

const extractedChannelData = (data) => {
    return {
        '@id':data['@id'],
        '@type':data['@type'],
        brand:data.brand,
        countryAware:data.countryAware,
        id:data.id,
        slug:data.slug,
        title:data.title,
        uuid:data.uuid,
    };
};

export const ChannelCreate = props =>{
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify = useNotify();
    const channels = useSelector((state)=>state.channel.details || []);
    const selectedBrand = useSelector(state => state.brand.brand);

    const onSuccess = async (data) =>{
        try{
            const extractedData = extractedChannelData(data)
            notify('Channel created successfully', 'info');
            redirect('/channels');
            const updatedChannels = [extractedData, ...channels]
            dispatch(setChannelDetails(updatedChannels));
            refresh();
        }catch(error){
            console.error('Error creating channel:',error);
            notify(`Error creating channel: ${error.message}`, 'warning');
        }
    };

    const onError=(error)=>{
        console.error('E-rror creating module type:', error);
        notify(`Error creating ${error.message}`, 'warning');
    }
    return (
        <Create {...props} mutationOptions = {{onSuccess, onError}} title = 'Create Channel'>
            <SimpleForm defaultValues={()=>({brand: '/brands/'+selectedBrand })}>
                <TextInput fullWidth source = 'title'/>
                <BooleanInput source='countryAware' />
            </SimpleForm>
        </Create>
    )
}