import { SET_PAGE} from '../actions/pageActions.js';

const initialState = {
    page:1,
};

const pageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGE:
            return {
                ...state,
                page: action.payload,
            };
        default:
            return state;
    }
};

export default pageReducer;