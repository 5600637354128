import { SET_ASSET_OVERRIDES_TOTAL_ITEMS, SET_ASSET_OVERRIDES_DETAILS} from '../actions/assetOverridesActions';

const initialState = {
    details: [],
    totalItems:0,
};

const assetsOverridesReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_ASSET_OVERRIDES_DETAILS:
            return{
                ...state,
                details: action.payload
            };
        case SET_ASSET_OVERRIDES_TOTAL_ITEMS:
        return {
            ...state,
            totalItems: action.payload,
        };
        default:
            return state;
    }
};

export default assetsOverridesReducer;