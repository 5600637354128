export const SET_CHANNEL_DATA='SET_CHANNEL_DATA';
export const setChannelData = (data) =>({
    type:SET_CHANNEL_DATA,
    payload:data
})

export const SET_CHANNEL_DETAILS = 'SET_CHANNEL_DETAILS';
export const setChannelDetails = (channel) => ({
    type: SET_CHANNEL_DETAILS,
    payload: channel,
});