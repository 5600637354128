export const SET_ASSET_OVERRIDES_TOTAL_ITEMS= 'SET_ASSET_OVERRIDES_TOTAL_ITEMS'
export const setAssetOverridesTotalItems = (totalItems) => ({
    type: 'SET_ASSET_OVERRIDES_TOTAL_ITEMS',
    payload: totalItems,
});

export const SET_ASSET_OVERRIDES_DETAILS= 'SET_ASSET_OVERRIDES_DETAILS'
export const setAssetOverridesDetails = (assetOverride) => ({
    type: 'SET_ASSET_OVERRIDES_DETAILS',
    payload: assetOverride,
});
