class ModuleTypeDataTransformer {
    supports(resource) {
        return resource === 'moduleTypes';
    }

    transformModuleTypeBeforeCreate(data) {
        let transformedData = { ...data };

        if (typeof transformedData.position === 'string') {
            transformedData.position = parseInt(transformedData.position);
            if (isNaN(transformedData.position)) {
                throw new Error('Position must be a valid number');
            }
        }
        return transformedData;
    }

    transformCollection(data) {
        return data['hydra:member'].map(resource => ({ ...resource, id: resource['code'] }));
    }

    transformSingle(data) {
        return { ...data, id: data['code'] };
    }

    transformSingleBeforeCreate(data, resource) {
        if (this.supports(resource)) {
            return this.transformModuleTypeBeforeCreate(data);
        }
        return { ...data, id: data['code'] };
    }
}

export default ModuleTypeDataTransformer;
