import React, {useState, useEffect, useCallback} from 'react';
import {
    useDataProvider,
    TopToolbar,
    Title,
    EditButton,
    TextInput,
    Edit,
    SimpleForm,
    ShowButton,
    SimpleShowLayout,
    TextField,
    DateField,
    Show,
    useNotify,
    useRefresh,
    useRedirect,
    useShowController,
    useEditController,
    Toolbar,
    DeleteButton,
    SaveButton
} from 'react-admin';
import SearchIcon from '@material-ui/icons/Search';

import {useSelector, useDispatch} from 'react-redux';

import { BRAND_DETAILS } from './Brands';
import { setDeviceDetails, setDeviceTotalItems, setDeviceModuleTypes } from '../actions/deviceActions';
import Loader from '../components/Loader';
import NotFound from '../components/Profile/NotFound/NotFound';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '../components/Pagination';
import { setPage } from '../actions/pageActions';
import { debounce } from 'lodash';

const useFetchDevices = () => {
    const page = useSelector(state => state.page.page || 1);
    const selectedBrand = useSelector((state)=> state.brand.brand);
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    const devices = useSelector(state => state.device.details || []);
    const [loading, setLoading] = useState(!devices || devices.length === 0);

    const fetchAllModuleTypes = async () => {
        try {
            const moduleParams = {
                pagination: { page: 1},
                filter: { brand: selectedBrand },
            }
            const { data } = await dataProvider.getList('moduleTypes', moduleParams);
            const moduleMap = data.reduce((acc,module)=>{
                acc[module.code] = module.title;
                return acc;
            },{})
            dispatch(setDeviceModuleTypes(moduleMap));
        }catch(error){
            console.error('Error fetching module types', error);
        }
    }

    const fetchDevices = async () => {
        setLoading(true);
        try {
            const deviceParams = {
                pagination: { page: page },
                filter: { brand: selectedBrand },
            };
            const { data, total } = await dataProvider.getList('devices', deviceParams);

            dispatch(setDeviceTotalItems(total));
            dispatch(setDeviceDetails(data));
        } catch (error) {
            console.error('Error fetching devices:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        fetchDevices();
    },[page, selectedBrand])

    useEffect(()=>{
        fetchAllModuleTypes();
    },[selectedBrand])

    useEffect(()=>{
        fetchDevices();
    },[])

    return loading;
}

const DeviceListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

export const DeviceList = () =>{
    const dispatch = useDispatch();
    const loadingDevices = useFetchDevices();
    const devices = useSelector(state => state.device.details || []);
    const dataProvider = useDataProvider();

    const dealerships = useSelector(state => state.device.dealerships);

    const page = useSelector(state => state.page.page);
    const totalItems = useSelector(state=>state.device.totalItems || 0);
    const selectedBrand = useSelector(state => state.brand.brand);
    
    const handlePageChange = (newPage) => {
        dispatch(setPage(newPage))
    };

    useEffect(() => {
        if(selectedBrand){
            dispatch(setPage(1));
        }
    }, [selectedBrand, dispatch]);

    const [searchDevicesQuery, setSearchDevicesQuery] = useState('');
    const [searchDevicesResults, setSearchDevicesResults] = useState([]);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        searchDevices('');
    }, [dataProvider]);

    const debouncedSearchDevices = useCallback(
        debounce((query) => {
            searchDevices(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDevices.cancel();
        };
    }, [debouncedSearchDevices]);

    const searchDevices = async (query) => {
        if(query.length > 2){
            try {
                setIsSearching(true)
                const response = await dataProvider.getList('devices',{
                    filter:{deviceNumber: query, brand: selectedBrand},
                    pagination:{page:1},
                    sort: {field: 'kvps', order: 'ASC'}
                });
                setSearchDevicesResults(response.data);
                setShowSearchResults(true);
                setIsSearching(false)
            }catch(error){
                console.error('Error fetching dealerships', dealerships);
                setIsSearching(false)
            }
        }else {
            setShowSearchResults(false);
            setSearchDevicesResults([]);
        }
    };

    const handleSearchDevicesChange = (event) => {
        const query = event.target.value
        setSearchDevicesQuery(query);
        debouncedSearchDevices(query);
    };

    if (loadingDevices) return <Loader/>;
    const devicesToDisplay = showSearchResults ? searchDevicesResults : devices;

    if (!devicesToDisplay.length && !loadingDevices) return <>
         <div className = 'customGrid_dealership'>
            <div className='search_container'>
                <input
                    className='countries_searchbar'
                    type='text'
                    placeholder='Search by device number'
                    value = {searchDevicesQuery}
                    onChange={handleSearchDevicesChange}
                />
                <SearchIcon className='search_icon'/>
            </div>
            <Title title = 'Devices' />
            <DeviceListActions />
            {isSearching ? <Loader/> :
            <table className='dealerships_table'>
                <thead>
                    <tr className='dealershipsHead'>
                        <th>Device Class</th>
                        <th>Brand</th>
                        <th>Device Number</th>
                        <th>Dealership</th>
                        <th>Title</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <div>No dealership found.</div>
                </tbody>
            </table>}
        </div></>
    return (
        <div className = 'customGrid_dealership'>
            <div className='search_container'>
                <input
                    className='countries_searchbar'
                    type='text'
                    placeholder='Search by device number'
                    value = {searchDevicesQuery}
                    onChange={handleSearchDevicesChange}
                />
                <SearchIcon className='search_icon'/>
            </div>
            <Title title = 'Devices' />
            <DeviceListActions />
            {isSearching ? <Loader/> :
            <table className='dealerships_table'>
                <thead>
                    <tr className='dealershipsHead'>
                        <th>Device Class</th>
                        <th>Brand</th>
                        <th>Device Number</th>
                        <th>Dealership</th>
                        <th>Title</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {devicesToDisplay.map(device => {
                        const brandCode = device.brand.split('/').pop();
                        const brand = BRAND_DETAILS.find(b=>b.code===brandCode);
                        const brandName = brand ? brand.name : device.brand;

                        return (
                            <tr key = {device.id} className='dealershipsRow'>
                                <td>{device.deviceClass}</td>
                                <td>{brandName}</td>
                                <td>{device.deviceNumber}</td>
                                <td>{device.dealership?.split('/').pop()}</td>
                                <td>{device.title}</td>
                                <td className='crud_buttons'>
                                    <EditButton className='edit-button' basepath='/devices' record={device} />
                                    <ShowButton className='show-button' basepath='/devices' record={device} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>}
            {!showSearchResults && <Pagination total={totalItems} current={page} onChange={handlePageChange} />}
        </div>
    )
}

export const DeviceEdit = (props) => {
    const dataProvider = useDataProvider();
    const selectedBrand = useSelector((state)=> state.brand.brand);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { isLoading, record } = useEditController();
    const device = record;
    const moduleTypes = useSelector(state => state.device.moduleTypes);
    const dealerships = useSelector(state => state.device.dealerships);
    const [searchDealershipsQuery, setSearchDealershipsQuery] = useState('');
    const [searchDealershipsResults, setSearchDealershipsResults] = useState('');
    const [isSearchingDealership, setIsSearchingDealership] = useState(false);
    const [selectedModuleTypes, setSelectedModuleTypes] = useState([]);
    const [selectedModuleType, setSelectedModuleType] = useState([]);
    const [availableModuleTypes, setAvailableModuleTypes] = useState([]);
    const [selectedDealership, setSelectedDealership] = useState({});

    useEffect(() => {
        if (device?.moduleTypes && moduleTypes) {
            const selectedTypes = device.moduleTypes.map(ele => {
                const id = ele.split('/').pop(); // Extract ID from IRI
                const title = moduleTypes[id];
                return { id, title };
            });
            setSelectedModuleTypes(selectedTypes);
        }

        const typesArray = Object.entries(moduleTypes).map(([id, value]) => ({
            id,
            title: value
        }));
        setAvailableModuleTypes(typesArray);
    }, [moduleTypes, device]);

    const searchDealerships = async (query) => {
        if (query.length>2) {
            try {
                setIsSearchingDealership(true)

                const response = await dataProvider.getList('dealerships', {
                    filter: { kvps: query, brand: selectedBrand },
                    pagination: { page: 1 },
                });

                setSearchDealershipsResults(response.data)
                setIsSearchingDealership(false)

            } catch (error) {
                console.error('Error fetching users:', error);
                setIsSearchingDealership(false)
            }
        } else {
            setSearchDealershipsResults('');
        }
    };

    useEffect(() => {
        searchDealerships('');
    }, [dataProvider]);

    const debouncedSearchDealerships = useCallback(
        debounce((query) => {
            searchDealerships(query);
        }, 1000),
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearchDealerships.cancel();
        };
    }, [debouncedSearchDealerships]);

    useEffect(() => {
        if (device?.dealership) {
            dataProvider.getOne('dealerships', {  id: device.dealership.split('/')[4], meta:{brand:selectedBrand} })
            .then(({ data }) => {
                setSelectedDealership(data);
            })
            .catch(error => {
                console.error('Error fetching dealership:', error);
            });
        }
    }, [dealerships, device]);

    if (isLoading) return <Loader/>

    if (!device) {
        return <NotFound resource='devices' />
    }

    const handleModuleTypeSelect = (moduleTypeId) => {
        const selected = availableModuleTypes.find(moduleType => moduleType.id === moduleTypeId);
        if (selected && !selectedModuleTypes.some(moduleType => moduleType.id === selected.id)) {
            setSelectedModuleTypes([...selectedModuleTypes, selected]);
            setSelectedModuleType(null);
        }
    };

    const handleModuleTypeRemove = (moduleTypeToRemove) => {
        setSelectedModuleTypes(prevModuleTypes =>
            prevModuleTypes.filter(moduleType => moduleType.id !== moduleTypeToRemove.id)
        );
        setSelectedModuleType(null);
    };

    const renderModuleTypeDropdown = () => {
        const moduleTypesToDisplay = availableModuleTypes.filter(moduleType =>
            !selectedModuleTypes.some(selectedModule => selectedModule.id === moduleType.id));

        return (
            <select className = 'channels_dropdown' onChange={(e) => handleModuleTypeSelect(e.target.value)} value={selectedModuleType}>
                <option value=''>Select a Module Type</option>
                {moduleTypesToDisplay.map(moduleType => (
                    <option className = 'channel' key={moduleType.id} value={moduleType.id}>
                        {moduleType.title}
                    </option>
                ))}
            </select>
        );
    };

    const renderModuleTypes = () => {
        return (
            <div>
                {selectedModuleTypes.map((moduleType, index) => (
                    <div className = 'channel' key={index}>
                        <span className='searched_name_or_title'>
                            {moduleType.title}
                        </span>
                        <button className='remove_button' type='button'
                            onClick={() => handleModuleTypeRemove(moduleType)} ><CloseIcon  />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const handleDealershipSelect = (dealer) => {
        setSelectedDealership(dealer);
        setSearchDealershipsQuery('')
    };

    const renderDealershipsInput = () => {
        return (
                    <>
                        <div className='countries'>
                        {selectedDealership.id && renderDealerships()}
                            <div className='search_container'>
                                <input
                                    className='countries_searchbar'
                                    type='text'
                                    placeholder='Search for dealerships by kvps'
                                    value = {searchDealershipsQuery}
                                    onChange={handleSearchDealershipsChange}
                                />
                                <SearchIcon className='search_icon'/>
                            </div>
                        {Array.isArray(searchDealershipsResults) && !isSearchingDealership &&
                            (searchDealershipsResults.length == 0
                            ? <div className = 'no-result'>no results</div>
                            :searchDealershipsResults.map(dealership => (
                            <div className='search-result-options' key={dealership.id} onClick={() => handleDealershipSelect(dealership)}>
                                {dealership.title}
                            </div>
                        )))}
                        {isSearchingDealership && <div>Searching...</div>}
                        </div>
                    </>
        );
    };

    const renderDealerships = () => {
        return (
            <div className = 'channel'>
                    {selectedDealership.title}
            </div>
        );
    };

    const handleSearchDealershipsChange = (event) => {
        const query = event.target.value
        setSearchDealershipsQuery(query);
        debouncedSearchDealerships(query);
    };

    const onSuccess = async (data) => {
        try {
            const updatedDealer = {
                title: data.title,
                description: data.description || '',
                moduleTypes: selectedModuleTypes.map(module => `/brands/${selectedBrand === 'N' ? 'V' : selectedBrand}/module-types/${module.id}`),
                dealership: selectedDealership['@id'],
            }

            await dataProvider.update('devices', {
                id: data.id,
                data: updatedDealer,
                previousData: data
            });

            notify('device data updated successfully', 'info');
            redirect(`/devices/${data.id}/show`);
            refresh();
        } catch (error) {
            console.error('Error updating user', error);
            notify(`Error updating ${error.message}`, 'warning');
        }
    };

    const DeviceEditToolbar = () => (
        <Toolbar style={{display: 'flex', 'justify-content': 'space-between'}}>
            <SaveButton  alwaysEnable type="button" mutationOptions={{ onSuccess }} />
            <DeleteButton />
        </Toolbar>
    );

    return (
        <Edit {...props} title='Device Edit'>
            <SimpleForm toolbar={<DeviceEditToolbar />}>
                <TextInput fullWidth source='title' label='Title'/>
                <TextInput fullWidth source='description' label ='Description' />

                <h3 className='channels_title ' >Module Types:</h3>
                <div className='channels'>
                    {renderModuleTypes()}
                    {renderModuleTypeDropdown()}
                </div>
                <h3 className='channels_title' >Dealership:</h3>
                {renderDealershipsInput()}

            </SimpleForm>
        </Edit>
    )
}
const ShowDealership = (props)=> {
    if(!props.id){
        return  <span className="MuiStack-root ra-field ra-field-dealership RaSimpleShowLayout-row css-8l66u2-MuiStack-root-RaLabeled-root">
                    <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-ycuoxz-MuiTypography-root">
                        <span>Dealership</span>
                    </p>
                    <span className="MuiTypography-root MuiTypography-body2 css-qqsp5g-MuiTypography-root">No Dealership assigned</span>
                </span>
    } else { 
        const { isLoading, record } = useShowController({ resource: 'dealership', id: props.id });
        if (isLoading) return <Loader/>

        return <span className="MuiStack-root ra-field ra-field-dealership RaSimpleShowLayout-row css-8l66u2-MuiStack-root-RaLabeled-root">
                    <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-ycuoxz-MuiTypography-root">
                        <span>Dealership</span>
                    </p>
                    <span className="MuiTypography-root MuiTypography-body2 css-qqsp5g-MuiTypography-root">{record.title}</span>
               </span>
    }
}

const ShowModuleTypes = (props)=> {
    const moduleTypes = useSelector(state => state.device.moduleTypes);
    const deviceModuleTypes = props.moduleTypes.map(ele => { 
        const id = ele.split('/').pop(); // Extract ID from IRI
        return moduleTypes[id]});
    return <span className="MuiStack-root ra-field ra-field-dealership RaSimpleShowLayout-row css-8l66u2-MuiStack-root-RaLabeled-root">
                <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-ycuoxz-MuiTypography-root">
                    <span>Module Types</span>
                </p>
                {deviceModuleTypes.length > 0
                ? deviceModuleTypes.map(type => <span key={type} className="MuiTypography-root MuiTypography-body2 css-qqsp5g-MuiTypography-root">{type}<br /></span>)
                : <span className="MuiTypography-root MuiTypography-body2 css-qqsp5g-MuiTypography-root">No module types available</span>
            }        
           </span>
}
   
export const DeviceShow = (props) => {
    const { isLoading, record } = useShowController();

    if (isLoading) return <Loader/>

    return <Show {...props } title = 'Device Show'>
            <SimpleShowLayout className = 'simpleShowLayout'>
                <TextField source = 'deviceClass'/>
                <TextField source = 'brand'/>
                <TextField source = 'deviceNumber'/>
                <ShowDealership id={record.dealership} />
                <TextField source = 'title'/>
                <TextField source = 'description'/>
                <ShowModuleTypes moduleTypes={record.moduleTypes}/>
                <span className="MuiStack-root ra-field ra-field-dealership RaSimpleShowLayout-row css-8l66u2-MuiStack-root-RaLabeled-root">
                    <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-ycuoxz-MuiTypography-root">
                        <span>Playlists</span>
                    </p>
                    {record.playlists.length > 0 
                        ? record.playlists.map(playlist => <span key={playlist['@id']} className="MuiTypography-root MuiTypography-body2 css-qqsp5g-MuiTypography-root">{playlist.title}<br /></span>)
                        : <span className="MuiTypography-root MuiTypography-body2 css-qqsp5g-MuiTypography-root">No playlists available</span>
                    }                 
                </span>
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </SimpleShowLayout>
        </Show>
}
