import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Pagination,
    // BooleanField,
    TopToolbar,
    SearchInput,
    FunctionField,
} from 'react-admin';

import Checkmark from '../assets/icons/Checkmark';
import X from '../assets/icons/X';

const CustomBooleanField = ({ record, source }) => {
    return (
        <span>
            {record[source] ? (
                <X/>
                ) : (
                <Checkmark/>
            )}
        </span>
    );
};

const permissionsFilters = [
    <SearchInput key='label' source='label' alwaysOn placeholder='Role Label' style={{padding:'1rem 0'}}/>
];

const PermissionsListActions = () => (
    <TopToolbar />
);

export const PermissionsList = () => (
    <List pagination={<Pagination rowsPerPageOptions={[]} perPage={30}/>} actions={<PermissionsListActions/>} filters={permissionsFilters} >
        <Datagrid bulkActionButtons={false} filters={false}>
            {/* <TextField source='value' sortable={false} /> */}
            <TextField source='label' sortable={false} />
            {/* <BooleanField source='deprecated' sortable={false} label='Granted' /> */}

            <FunctionField
                sortable={false}
                label='Granted'
                source='deprecated'
                render={record=>(
                    <CustomBooleanField record={record} source='deprecated' label='Granted' />
                )}
            />
        </Datagrid>
    </List>
);
