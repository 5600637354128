import {
    List,
    Datagrid,
    TextInput,
    TopToolbar,
    SimpleForm,
    CreateButton,
    Create,
    SelectInput,
    useListController,
    FunctionField
} from 'react-admin';
import React from 'react';

import { SplashScreenField } from '../fields/SplashScreenField';
import './styles/Custom.css'
import Loader from '../components/Loader';

const BrandListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export const SplashScreenInput = (props) => {
    const { source, record = {}, ...rest } = props;
    const splashScreenUrl = record[source] || '';
    const renderPreview = () => {
        if (!splashScreenUrl) return null;
        return (
            <a href={splashScreenUrl} target='_blank' rel='noopener noreferrer' style={{ marginTop: '10px' }}>
                <img src={splashScreenUrl} style={{ width: 'auto', height: '270px' }} />
            </a>
        );
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
            <TextInput source={source} record={record} {...rest} fullWidth multiline />
            {renderPreview()}
        </div>
    );
};

export const BRAND_DETAILS = [
    {'code':'A', 'name':'Audi'},
    // {'code':'C', 'name':'Škoda'},
    // {'code':'E','name':'Bentley'},
    // {'code':'G', 'name':'Bugatti'},
    // {'code':'L','name':'Lamborghini'},
    // {'code':'M','name':'M.A.N'},
    {'code': 'S', 'name':'SEAT CUPRA'},
    {'code':'V', 'name':'Volkswagen'},
    {'code':'N', 'name': 'Volkswagen Nutzfahrzeuge'}
];


const BrandSelectInput = ({ source, ...rest }) => {
    const choices = BRAND_DETAILS.map(brand => ({ id: brand.code, name: brand.name }));
    return <SelectInput {...rest} source={source} choices={choices} />;
};


export const BrandList = () => {

    const { isLoading } = useListController();

    if (isLoading) {
        return <Loader />;
    }
    
    const postRowStyle = (record) =>
    ({ display: record.code === 'N' && 'none'});

    return (
        <List pagination = {null} actions={<BrandListActions />} >
            <Datagrid rowSx={postRowStyle} className='customDataGrid_brands' filters={false} bulkActionButtons={false}>
                <FunctionField
                    label="brand"
                    render={record => {
                        const brand = BRAND_DETAILS.find(b => b.code === record.code);
                        return brand ? brand.name : record.code
                    }}
                />
                <SplashScreenField source='splashScreen' sortable={false} />
            </Datagrid>
        </List>
    );
};

export const BrandCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
        <BrandSelectInput source = 'code' label ='Brand Code' fullWidth />
            <TextInput source='name' fullWidth label= 'Brand Name' />
            <TextInput source='splashScreen' fullWidth label= 'Splash Screen Image URL' multiline />
        </SimpleForm>
    </Create>
);